import { MdErrorOutline } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { Button, DatePicker, Input, Select, Spin, Upload } from "antd";
import { ReactComponent as ShadowImg } from "../../../../assets/images/shadowImgs.svg";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import BaseService from "../../../../helpers/baseServices";
import basicElectronics_img from "../../../../assets/images/bigImg.png";
import type { UploadProps } from "antd";
import { PiTrashLight } from "react-icons/pi";
import { useParams } from "react-router-dom";
import MyEditor from "../Policy/Editor";
import Quill from "quill";
import RangeStatic from "quill";
import { ToastService } from "../../../../components/shared/ToastNotifications/ToastNotifications";

type ExtendedUploadProps = UploadProps & { action: string };

const { TextArea } = Input;

type UIState = {
  eventImage: { url: string; filename: string }[];
  eventName: string;
  eventOwner: string;
  eventDate: string;
  eventAbout: string;
  eventDescription: string;
  eventCategory: string;
  isCreating: boolean;
};

type Action =
  | {
      type: "SET_CREATE_EVENT_IMAGE";
      payload: { url: string; filename: string }[];
    }
  | {
      type: "SET_DELETED_EVENT_IMAGE";
      payload: { url: string; filename: string }[];
    }
  | { type: "SET_CREATE_EVENT_NAME"; payload: string }
  | { type: "SET_CREATE_EVENT_OWNER"; payload: string }
  | { type: "SET_EVENT_DATE"; payload: string }
  | { type: "SET_CREATE_EVENT_ABOUT"; payload: string }
  | { type: "SET_CREATE_EVENT_DESCRIPTION"; payload: string }
  | { type: "SET_EVENT_CATEGORY"; payload: string }
  | { type: "IS_CREATING"; payload: boolean }
  | { type: "SET_EVENT_ALL"; payload: any }
  | { type: "INIT_STATE"; payload: UIState };

const reducer = (state: UIState, action: Action) => {
  switch (action.type) {
    case "INIT_STATE":
      return { ...state, ...action.payload };
    case "SET_CREATE_EVENT_IMAGE":
      return { ...state, eventImage: action.payload };
    case "SET_DELETED_EVENT_IMAGE":
      return { ...state, eventImage: action.payload };
    case "SET_CREATE_EVENT_NAME":
      return { ...state, eventName: action.payload };
    case "SET_EVENT_DATE":
      return { ...state, eventDate: action.payload };
    case "SET_CREATE_EVENT_OWNER":
      return { ...state, eventOwner: action.payload };
    case "SET_EVENT_CATEGORY":
      return { ...state, eventCategory: action.payload };
    case "SET_CREATE_EVENT_ABOUT":
      return { ...state, eventAbout: action.payload };
    case "SET_CREATE_EVENT_DESCRIPTION":
      return { ...state, eventDescription: action.payload };
    case "IS_CREATING":
      return { ...state, isCreating: action.payload };
    case "SET_EVENT_ALL":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const initialState: UIState = {
  eventImage: [],
  eventDate: "",
  eventName: "",
  eventAbout: "",
  eventDescription: "",
  eventCategory: "",
  eventOwner: "",
  isCreating: false,
};

const getInitialState = (): UIState => {
  const savedState = localStorage.getItem("state");
  return savedState
    ? JSON.parse(savedState)
    : {
        eventImage: [],
        eventName: "",
        eventDate: "",
        eventAbout: "",
        eventDescription: "",
        eventOwner: "",
        eventCategory: "",
        isCreating: false,
      };
};

const Infor = forwardRef((props: { id: string | undefined }, ref) => {
  const [Filename, setFilename] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [ImageLoading, setImageLoading] = useState(false);
  const [img, setImg] = useState("");
  const { id } = useParams();
  const prevIdRef = useRef<string | undefined>();
  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [lastChange, setLastChange] = useState<any>(null);
  const quillRef = useRef<Quill | null>(null);
  const [range, setRange] = useState<RangeStatic | null>(null);
  const aboutEditorRef = useRef<Quill | null>(null);
  const descriptionEditorRef = useRef<Quill | null>(null);

  const GetEventData = async () => {
    setLoading(true);
    try {
      const results = await BaseService.get_api(
        `/robocentre/event/event-single/${props.id}`
      );
      const { payload } = results.data;
      const { name, about, description, image, eventDate, status } = payload;
      const eventImage = image.map((imgUrl: string) => {
        const urlParts = imgUrl.split("/");
        const filename = urlParts[urlParts.length - 1];
        return { url: imgUrl, filename };
      });
      if (aboutEditorRef.current) {
        aboutEditorRef.current.clipboard.dangerouslyPasteHTML(about);
      }
      if (descriptionEditorRef.current) {
        descriptionEditorRef.current.clipboard.dangerouslyPasteHTML(
          description
        );
      }

      let date;
      let dateString;

      if (!eventDate) {
        date = new Date();
      } else {
        date = new Date(parseInt(eventDate));
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        dateString = `${year}-${month}-${day}`;
      }

      // console.log("eventDate", payload);
      dispatch({
        type: "SET_EVENT_ALL",
        payload: {
          eventName: name,
          eventAbout: about,
          eventDescription: description,
          eventCategory: status,
          eventImage: eventImage,
          eventDate: dateString,
        },
      });

      // console.log("this is the payload", payload);
    } catch (error) {
      console.log("This is teh error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const savedState: any = localStorage.getItem("state");
    const currentId = props.id;

    if (currentId) {
      // console.log("is ids", currentId);
      if (currentId !== prevIdRef.current) {
        // console.log("inside ids", currentId);
        GetEventData();
        prevIdRef.current = currentId;
      }
    } else {
      // console.log("not", currentId);
      dispatch({ type: "INIT_STATE", payload: JSON.parse(savedState) });
    }
  }, [props.id, dispatch]);
  // useEffect(() => {
  //   localStorage.setItem("state", JSON.stringify(state));
  //   // console.log("This is all the date", state);
  // }, [state]);

  const onEventHandler = (name: string) => {
    dispatch({ type: "SET_CREATE_EVENT_NAME", payload: name });
  };

  const onEventAbout = (about: string) => {
    dispatch({ type: "SET_CREATE_EVENT_ABOUT", payload: about });
  };
  const onEventDescription = (description: string) => {
    dispatch({ type: "SET_CREATE_EVENT_DESCRIPTION", payload: description });
  };
  const onEventOwner = (description: string) => {
    dispatch({ type: "SET_CREATE_EVENT_OWNER", payload: description });
  };
  const onEventCategoryChange = (value: string) => {
    console.log("this is the date", value);
    dispatch({ type: "SET_EVENT_CATEGORY", payload: value });
  };

  useImperativeHandle(ref, () => ({
    saveToLocalStorage() {
      localStorage.setItem("state", JSON.stringify(state));
    },
    async updateEvents() {
      // setIsCreating(true);
      // props.onImageLoading(true);

      try {
        const payload = {
          _id: id,
          name: state.eventName,
          about: state.eventAbout,
          description: state.eventDescription,
          image: [...state.eventImage.map((image: { url: any }) => image.url)],
          status: state.eventCategory,
          eventDate: state.eventDate || Date.now().toString(),
        };
        const results = await BaseService.put_api(
          "/robocentre/event/event",
          payload
        );
        ToastService.ShowSuccess("Updated Successfully !");
        // console.log("Sending payload:", payload);
      } catch (error: any) {
        ToastService.ShowError(error.message);
      } finally {
        // setIsCreating(false);
        // props.onImageLoading(false);
      }
    },
  }));

  interface ExtendedUploadProps extends UploadProps {
    onChange: (info: any) => void;
  }

  const myprops: ExtendedUploadProps = useMemo(
    () => ({
      action: "",
      async onChange(info) {
        const file = info.file.originFileObj;
        if (file) {
          setFilename(file.name);
          setImageLoading(true);
          try {
            const mimeType = file.type;
            const fileFormat = mimeType.split("/")[1];
            const imgRes: any = await BaseService.file_upload(
              file.name,
              file,
              fileFormat
            );

            // console.log("imgRes : ", imgRes);

            setImg(imgRes?.data?.url);
            dispatch({
              type: "SET_CREATE_EVENT_IMAGE",
              payload: [{ url: imgRes?.data?.url, filename: file.name }],
            });
          } catch (error: any) {
            info.file.status = "error";
            console.error("imgUploadErr:", error.message);
          } finally {
            setImageLoading(false);
          }
        }
      },
    }),
    [dispatch]
  );
  const handleDeleteImg = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const updatedImageUrls = state.eventImage.filter(
      (item: any) => item.filename == Filename
    );

    console.log("updatedImageUrls", updatedImageUrls);

    dispatch({
      type: "SET_DELETED_EVENT_IMAGE",
      payload: updatedImageUrls,
    });

    setImg("");
    setFilename("");
  };
  useEffect(() => {
    console.log("State : ", state);
  }, [state]);
  const onChange: any = (date: any, dateString: any) => {
    dispatch({ type: "SET_EVENT_DATE", payload: dateString });
  };

  return (
    <div className="drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] mt-10 bg-white  mb-10 rounded-[10px] pb-10 px-6">
      <Spin spinning={isLoading}>
        <div className="flex  items-center gap-4 pt-6">
          <MdErrorOutline className="w-[24px] text-[#6D31ED] h-[24px]" />
          <h1 className="text-[20px] font-[600] text-[#171A1F]">
            General info
          </h1>
        </div>
        <p className="text-[21px] text-[#171A1F] font-[600] my-4">Album</p>

        <p className="text-[14px] font-[400] mb-2">
          Upload the event cover to capture your audience's attention
        </p>

        {!state.eventImage[0]?.url ? (
          <ShadowImg
            className="rounded-[10px]"
            style={{ width: "100%", height: "auto" }}
          />
        ) : (
          <div
            className="rounded-[10px]"
            style={{
              width: "100%",
              height: "260px",
              backgroundImage: `url(${state.eventImage[0]?.url || ""})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        )}
        <div className="flex items-center  justify-between mt-4 gap-4">
          {!state.eventImage[0]?.filename ? (
            <span className="text-[#6F7787]">default-02.png</span>
          ) : (
            <span className="text-[#6F7787]">
              {state.eventImage[0]?.filename}
            </span>
          )}

          <div className="h-[60px] w-[42%] ">
            <Upload.Dragger
              showUploadList={false}
              style={{ borderColor: "#6666EA" }}
              {...myprops}
              className="w-full h-full  relative"
            >
              {ImageLoading ? (
                <p className="bg-green-300 w-full text-center rounded-lg">
                  Uploading...
                </p>
              ) : state && state.eventImage[0]?.url ? (
                <div className="flex gap-2 items-center ">
                  <div className="w-[30px] h-[30px]">
                    <img
                      src={state.eventImage[0]?.url}
                      alt="uploaded"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-2 mr-auto max-w-full overflow-hidden text-ellipsis ">
                    {Filename || state.eventImage[0]?.filename}
                  </div>

                  <button
                    className="hover:text-red-900  text-red-500"
                    onClick={handleDeleteImg}
                  >
                    <PiTrashLight className="h-5 w-5" />
                  </button>
                </div>
              ) : (
                <div className="flex gap-2 items-center">
                  <div className="w-6 h-6 ml-3">
                    <img
                      src={basicElectronics_img}
                      alt="default"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div className="flex ml-6 items-center gap-2 mr-auto">
                    <p>click here to upload your banner</p>
                  </div>
                </div>
              )}
            </Upload.Dragger>
          </div>
        </div>
        <div className="mt-5">
          <span className="text-[#0F0F37]">Event name</span>

          <Input
            placeholder="Enter event name"
            className="noplaceholder h-[48px] rounded-[10px]"
            value={state.eventName}
            onChange={(e) => onEventHandler(e.target.value)}
          />
        </div>
        <div className="mt-5">
          <span className="text-[#0F0F37]">About</span>
          <TextArea
            rows={4}
            placeholder="Enter your description"
            className="noplaceholder h-[48px] rounded-[10px]"
            value={state.eventAbout}
            onChange={(e) => onEventAbout(e.target.value)}
          />
          {/* <MyEditor
            readOnly={readOnly}
            height={"10vh"}
            onTextChange={(delta, oldDelta, source) => {
              setLastChange({ delta, oldDelta, source });
              const content = aboutEditorRef.current?.root.innerHTML || "";
              onEventAbout(content);
            }}
            onSelectionChange={(range, oldRange, source) => {
              setRange(range);
            }}
            ref={aboutEditorRef}
          /> */}
        </div>
        <div className="mt-5">
          <span className="text-[#0F0F37]">Event Details</span>
          <MyEditor
            readOnly={readOnly}
            height={"20vh"}
            onTextChange={(delta, oldDelta, source) => {
              setLastChange({ delta, oldDelta, source });
              const content =
                descriptionEditorRef.current?.root.innerHTML || "";
              onEventDescription(content);
            }}
            onSelectionChange={(range, oldRange, source) => {
              setRange(range);
            }}
            ref={descriptionEditorRef}
          />
        </div>
        {/* <section className="flex gap-4 w-full">
          <div className="mt-5 w-full">
            <span className="text-[#0F0F37]">DRAFTED / PUBLISHED</span>
            <Select
              allowClear
              className="w-full h-[48px]"
              options={[
                { value: "DRAFTED", label: "DRAFTED" },
                { value: "PUBLISHED", label: "PUBLISHED" },
              ]}
              value={state.eventCategory}
              onChange={onEventCategoryChange}
            />
          </div>
        </section> */}
      </Spin>
    </div>
  );
});

export default Infor;
