import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import signinImg from "../../../assets/images/signinImg.png";
import logo from "../../../assets/images/logoImg_.svg";
import BaseService from "../../../helpers/baseServices";
import { StorageBox } from "../../../helpers/stroage";
import { basicSchema } from "./schema";
import { SlEye } from "react-icons/sl";
import { ToastService } from "../../../components/shared/ToastNotifications/ToastNotifications";
const Signin = () => {
  const navigate = useNavigate();
  const [openForgotModal, setOpenForgotModal] = useState(false);
  const [valuess, setValues] = useState({ showPassword: false });

  const onSubmit = async (values: any, actions: any) => {
    try {
      const { email, password } = values;
      const res = await BaseService.post_api("/robocentre/auth/signin", {
        email,
        password,
      });

      if (res?.data?.payload && Object.keys(res?.data?.payload).length > 0) {
        StorageBox.saveAccessToken(res.data.token);
        StorageBox.saveUserData(res.data.payload);
        navigate("/activate-account");
        ToastService.ShowSuccess("Signin Successfully !");
      } else {
        StorageBox.saveTempToken(res.data.token);
        console.log("temperal token", res.data.token);
        setTimeout(() => navigate("/verify"), 1000);
      }
    } catch (error: any) {
      ToastService.ShowError(error.message);
      console.log("error : ", error.message);
    }
  };
  // actions.resetForm();

  const handleOpenForgotModal = () => {
    setOpenForgotModal(!openForgotModal);
  };

  const ViewPassword = () => {
    setValues({ ...valuess, showPassword: !valuess.showPassword });
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: basicSchema,
    onSubmit,
  });

  return (
    <>
      <div className="h-[100vh] ">
        <div className="h-[10vh]  bg-[#0F1017]">
          <div className="flex items-center  h-full  w-full ">
            <img className="block ml-[20px]" src={logo} alt="Logo" />
          </div>
        </div>
        <div className="h-[90vh] grid grid-cols-2">
          <div
            className="your-custom-class"
            style={{
              backgroundImage: `url(${signinImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "100%",
            }}
          ></div>

          <div className="flex  items-center justify-center h-full">
            <div className=" bg-white w-[60%] rounded-[20px]">
              <div>
                <p className="text-[40px] font-[700]">Welcome back</p>
                <p className="text-[#57585D] ">Log into your Account </p>
              </div>
              <form onSubmit={handleSubmit} className="">
                <div className="form-group mt-[40px]">
                  <p>Email</p>
                  <input
                    className={`${
                      errors.email && touched.email
                        ? "border-[#fc8181]"
                        : "border-[#9494F5]"
                    } border-[1px] h-[48px] pl-2  rounded-[5px] w-full  focus:border-default_blue focus:outline-none disabled:cursor-not-allowed`}
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="hola@soytian.tech"
                    required
                  />
                  {errors.email && touched.email && (
                    <span className="text-[#fc8181]">{errors.email}</span>
                  )}
                </div>
                <div className="form-group mt-[16px] ">
                  <div className="relative">
                    <p>Password</p>
                    <input
                      className={`${
                        errors.password && touched.password
                          ? "border-[#fc8181]"
                          : "border-[#9494F5]"
                      } border-[1px] h-[48px] pl-2 rounded-[5px] w-full  focus:border-default_blue focus:outline-none disabled:cursor-not-allowed`}
                      type={valuess.showPassword ? "text" : "password"}
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Password"
                      required
                    />
                    <Link
                      to="#"
                      className="absolute right-3 bottom-[13px]"
                      onClick={ViewPassword}
                    >
                      <SlEye className="w-5 h-5" />
                    </Link>
                  </div>
                  {errors.password && touched.password && (
                    <span className="text-[#fc8181]">{errors.password}</span>
                  )}
                </div>

                <div className="form-group text-center mt-[40px]">
                  <button
                    className="  h-[49px]  font-[500] text-[18px] text-white bg-gradient-to-r from-[#F7426F] to-[#F87312] rounded-[8px] form-control active:bg-default_blue w-full   disabled:cursor-not-allowed disabled:bg-gray-500 focus:bg-default_blue"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 24, color: "black" }}
                            spin
                          />
                        }
                      />
                    ) : (
                      "Continue"
                    )}
                  </button>
                </div>
                {/* <div className="flex justify-center mt-[24px] items-center gap-2 ">
                  <p>Forgot Password?</p>
                  <Link
                    className="text-[#F74070]  font-semibold"
                    to="#"
                    onClick={handleOpenForgotModal}
                  >
                    Reset
                  </Link>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* {openForgotModal && (
        <ForgotPwdModal
          openPwdModal={openForgotModal}
          handleOpenPwdModal={handleOpenForgotModal}
        />
      )} */}
    </>
  );
};

export default Signin;
