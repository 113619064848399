import { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Modal, Spin } from "antd";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import { BsPersonGear } from "react-icons/bs";
import BaseService from "../../../helpers/baseServices";
import * as FiIcons from "react-icons/fi";
import * as ReactDOMServer from "react-dom/server";
import { MdHouseSiding } from "react-icons/md";

interface ModalProps {
  open: boolean;
  id: string | undefined;
  setOpenSMS: (open: boolean) => void;
  handleReFetch?: any;
}

const AddFunction = ({ open, id, setOpenSMS, handleReFetch }: ModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string | undefined>();
  const [TextAreaValue, setTextAreaValue] = useState<string | undefined>();

  const handleCancel = () => {
    setOpenSMS(false);
  };

  const handleOk = () => {
    getSingleNotifications();
  };

  const getSingleNotifications = async () => {
    setLoading(true);
    try {
      const payload = {
        eventId: id,
        name: title,
        description: TextAreaValue,
      };
      console.log("Sending payload:", payload);
      const results = await BaseService.post_api(
        "/robocentre/event/job-function",
        payload
      );
      handleReFetch();
      setOpenSMS(false);
      console.log("API results:", results);
    } catch (error: any) {
      console.log("Message sending  error:", error.message);
      setOpenSMS(true);
    } finally {
      setLoading(false);
    }
  };

  const handleTitle = (value: string | undefined) => {
    setTitle(value);
  };
  const handleTextArea = (value: string | undefined) => {
    setTextAreaValue(value);
  };

  return (
    <>
      <Modal
        width={"35%"}
        open={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        okText="Add Function"
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "30vh" } }}
      >
        <Spin
          spinning={loading}
          //   indicator={
          //     <div className="flex justify-center items-center  ">
          //       <LoadingDataComponent />
          //     </div>
          //   }
        >
          <div>
            <div className="flex gap-6 items-center">
              <BsPersonGear
                size={"2em"}
                className="text-[600] text-[#0F0F37]"
              />

              <p className="text-[30px] font-[600] text-[#0F0F37]">
                Add attendee Function
              </p>
            </div>

            {/* <div className="w-full border-[1px] border-gray-300 "></div> */}
            <section className="my-6 flex flex-col gap-6">
              <div className="">
                <span className="text-[#0F0F37]">Title</span>
                <Input
                  placeholder="Enter your role"
                  className="noplaceholder h-[48px] rounded-[10px]"
                  value={title}
                  onChange={(e) => handleTitle(e.target.value)}
                />
              </div>
              <div className="">
                <span className="text-[#0F0F37]">Description</span>
                <TextArea
                  rows={4}
                  placeholder="describe your role"
                  className="noplaceholder h-[48px] rounded-[10px]"
                  value={TextAreaValue}
                  onChange={(e) => handleTextArea(e.target.value)}
                />
              </div>
            </section>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default AddFunction;
