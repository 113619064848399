import { useEffect, useRef, useState } from "react";
import { IoIosArrowDropright } from "react-icons/io";
import { Button, Checkbox, Progress } from "antd";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PersonalInformation from "../AuthenticationPage/ActivateAccount/Tabs/PersonalInformation";
import OrganisationInformation from "../AuthenticationPage/ActivateAccount/Tabs/OrganisationInformation";
import MyProfile from "./Tabs/MyProfile";
import { GoPerson } from "react-icons/go";
import { TbPasswordFingerprint } from "react-icons/tb";
import { CiEdit } from "react-icons/ci";
import { RiLogoutCircleRLine } from "react-icons/ri";
import EditProfile from "./Tabs/EditProfile";
import ChangePassword from "./Tabs/ChangePassword";
import Logout from "./Tabs/Logout";
const Profile = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState(0);

  const tabNames = [
    { title: "Profile", icon: <GoPerson size={"1.5em"} /> },
    {
      title: "Log out",
      icon: <RiLogoutCircleRLine className="text-[#FF5964]" size={"1.5em"} />,
    },

    // {
    //   title: "Change Password",
    //   icon: <TbPasswordFingerprint size={"1.5em"} />,
    // },
    // { title: "Edit Profile", icon: <CiEdit size={"1.5em"} /> },
  ];

  const Tabs = [<MyProfile />, <Logout />, <ChangePassword />];
  // <EditProfile />
  const handleTabClick = (index: number, tabName: string) => {
    setActiveTab(index);
    if (tabName === "Attendee") {
      navigate(`/events/${id}/attendee`);
    } else {
      navigate(`/events/${id}`);
    }
  };

  return (
    <div className="w-full bg-default_bg  h-screen overflow-y-auto p-5 font-baloo">
      <div className="mt-[50px]  w-[70%] mr-auto ml-auto">
        <Button
          className="custom-button  rounded-[20px] text-[#6666EA] border-1 border-solid border-[#6666EA]"
          icon={<FaArrowLeft />}
          onClick={() => navigate("/dashboard")}
        >
          Back
        </Button>
      </div>
      <section className="w-full flex  items-center  justify-center gap-10 ">
        <div className="mt-[30px]  w-[70%] flex  gap-10 ">
          <div className="flex sticky top-0 h-[300px] drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] w-[300px]     flex-col   ">
            <section className="py-4 bg-white   rounded-[10px]">
              {tabNames.map((d, i) => (
                <div
                  key={i}
                  className={`${
                    activeTab === i
                      ? "bg-[#F9F9FF]  font-[600] border-l-4  border-l-[#6666EA]"
                      : " text-[#252363]  "
                  }   text-[#252363] bg-white  cursor-pointer`}
                  onClick={() => setActiveTab(i)}
                >
                  <div className=" gap-4  flex  px-6 py-4 ">
                    <div>{d?.icon}</div>
                    <p
                      style={{
                        color: `${
                          d?.title === "Log out" ? "#FF5964" : "#252363"
                        }`,
                      }}
                    >
                      {d?.title}
                    </p>
                  </div>
                </div>
              ))}
            </section>
          </div>
          <div className="flex-auto rounded-[10px] bg-white  drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)]">
            {Tabs[activeTab]}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Profile;
