import { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Modal, Spin } from "antd";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import { BsPersonGear } from "react-icons/bs";
import BaseService from "../../../helpers/baseServices";
import * as ReactDOMServer from "react-dom/server";
import { MdHouseSiding } from "react-icons/md";
import { MdOutlineEngineering } from "react-icons/md";
import { GiMadScientist } from "react-icons/gi";
import { MdOutlineArchitecture } from "react-icons/md";
import { FaUserDoctor } from "react-icons/fa6";
import { GiNurseFemale } from "react-icons/gi";
import { GiFemaleVampire } from "react-icons/gi";
import { SiAzuredevops } from "react-icons/si";
import { GrUserManager } from "react-icons/gr";
import { MdOutlineDeveloperMode } from "react-icons/md";
import { SiHiveBlockchain } from "react-icons/si";

interface ModalProps {
  open: boolean;
  id: string | undefined;
  setOpenSMS: (open: boolean) => void;
  handleReFetch?: any;
}

const AddRole = ({ open, id, setOpenSMS, handleReFetch }: ModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string | undefined>();
  const [TextAreaValue, setTextAreaValue] = useState<string | undefined>();
  const [icon, setIcon] = useState<string>("");
  const [selectedIconIndex, setSelectedIconIndex] = useState(null);

  const handleIconClick = (ico: any, index: any) => {
    setSelectedIconIndex(index);
    setIcon(ReactDOMServer.renderToString(ico));
  };

  const handleCancel = () => {
    setOpenSMS(false);
  };

  const handleOk = () => {
    getSingleNotifications();
  };

  const getSingleNotifications = async () => {
    setLoading(true);
    try {
      const payload = {
        eventId: id,
        name: title,
        description: TextAreaValue,
        icon: icon,
      };
      console.log("Sending payload:", payload);
      const results = await BaseService.post_api(
        "/robocentre/event/roles",
        payload
      );
      setOpenSMS(false);
      handleReFetch();
    } catch (error: any) {
      console.log("Message sending  error:", error.message);
      setOpenSMS(true);
    } finally {
      setLoading(false);
    }
  };

  const handleTitle = (value: string | undefined) => {
    setTitle(value);
  };
  const handleTextArea = (value: string | undefined) => {
    setTextAreaValue(value);
  };

  const icons = [
    <MdOutlineEngineering className="w-10 h-10" />,
    <GiMadScientist className="w-10 h-10" />,
    <MdOutlineArchitecture className="w-10 h-10" />,
    <FaUserDoctor className="w-10 h-10" />,
    <GiNurseFemale className="w-10 h-10" />,
    <GiFemaleVampire className="w-10 h-10" />,
    <SiHiveBlockchain className="w-10 h-10" />,
    <SiAzuredevops className="w-10 h-10" />,
    <GrUserManager className="w-10 h-10" />,
    <MdOutlineDeveloperMode className="w-10 h-10" />,
  ];

  return (
    <>
      <Modal
        width={"35%"}
        open={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        okText="Add Type"
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "30vh" } }}
      >
        <Spin
          spinning={loading}
          //   indicator={
          //     <div className="flex justify-center items-center  ">
          //       <LoadingDataComponent />
          //     </div>
          //   }
        >
          <div>
            <div className="flex gap-6 items-center">
              <BsPersonGear
                size={"2em"}
                className="text-[600] text-[#0F0F37]"
              />

              <p className="text-[30px] font-[600] text-[#0F0F37]">
                Add attendee type
              </p>
            </div>

            {/* <div className="w-full border-[1px] border-gray-300 "></div> */}
            <section className="my-6 flex flex-col gap-6">
              <div className="">
                <span className="text-[#0F0F37]">Title</span>
                <Input
                  placeholder="Enter your role"
                  className="noplaceholder h-[48px] rounded-[10px]"
                  value={title}
                  onChange={(e) => handleTitle(e.target.value)}
                />
              </div>
              <div className="">
                <span className="text-[#0F0F37]">Description</span>
                <TextArea
                  rows={4}
                  placeholder="describe your role"
                  className="noplaceholder h-[48px] rounded-[10px]"
                  value={TextAreaValue}
                  onChange={(e) => handleTextArea(e.target.value)}
                />
              </div>
              <div className="">
                <span className="text-[#0F0F37]">Icon</span>
                <div className="grid grid-cols-5 gap-2 ">
                  {icons.map((ico, i) => {
                    return (
                      <div
                        key={i}
                        className={`cursor-pointer rounded-[10px] bg-[#d9d9d9] p-1 flex justify-center items-center ${
                          selectedIconIndex === i
                            ? "border-[2px] border-solid border-[#6666ea]"
                            : ""
                        }`}
                        onClick={() => handleIconClick(ico, i)}
                      >
                        {ico}
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default AddRole;
