import { Button, Input, Upload, UploadProps } from "antd";
import { RiDeleteBin7Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useEffect, useReducer, useState } from "react";
import BaseService from "../../../helpers/baseServices";
import { ToastService } from "../../../components/shared/ToastNotifications/ToastNotifications";
import avata from "../../../assets/images/avata.png";
import basicElectronics_img from "../../../assets/images/shadowImgs.svg";

interface AuthUI {
  firstName: string;
  lastName: string;
  Email: string;
  telephone: string;
  gender?: string;
  image?: string;
}

type Action =
  | { type: "SET_FIELD"; field: keyof AuthUI; payload: string }
  | { type: "SET_INIT"; payload: AuthUI }
  | { type: "SET_DELETED_EVENT_IMAGE"; payload: string };

const reducer = (state: AuthUI, action: Action): AuthUI => {
  switch (action.type) {
    case "SET_FIELD":
      return { ...state, [action.field]: action.payload };
    case "SET_DELETED_EVENT_IMAGE":
      return { ...state, image: action.payload };
    case "SET_INIT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const initState: AuthUI = {
  firstName: "",
  lastName: "",
  Email: "",
  telephone: "",
  gender: "",
  image: "",
};

const MyProfile = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initState);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ImageLoading, setImageLoading] = useState(false);
  interface ExtendedUploadProps extends UploadProps {
    onChange: (info: any) => void;
  }
  const getPersonalInformation = async () => {
    setLoading(true);
    try {
      const { data } = await BaseService.get_api(`/robocentre/user/details`);
      const {
        firstName,
        lastName,
        phone: telephone,
        email: Email,
        gender,
        image,
      } = data.payload;

      dispatch({
        type: "SET_INIT",
        payload: {
          firstName,
          lastName,
          telephone,
          Email,
          gender,
          image: image ?? "",
        },
      });
    } catch (error: any) {
      console.log("Message sending error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const upadtePersonalInfor = async () => {
    setLoading(true);
    try {
      const { firstName, lastName, telephone, gender, image } = state;
      const payload = { firstName, lastName, phone: telephone, gender, image };

      await BaseService.put_api("/robocentre/user/details", payload);
      setRefresh((prev) => !prev);
      ToastService.ShowSuccess("Updated Successfully !");
    } catch (error: any) {
      ToastService.ShowError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPersonalInformation();
  }, [refresh]);

  const handleInputChange = (field: keyof AuthUI, value: string) => {
    dispatch({ type: "SET_FIELD", field, payload: value.trim() });
  };

  const myprops: UploadProps = {
    action: "",
    async onChange(info) {
      const file = info.file.originFileObj;
      if (file) {
        setImageLoading(true);
        try {
          const fileFormat = file.type.split("/")[1];
          const imgRes: any = await BaseService.file_upload(
            file.name,
            file,
            fileFormat
          );

          dispatch({
            type: "SET_FIELD",
            field: "image",
            payload: imgRes?.data?.url,
          });
          ToastService.ShowSuccess("Uploaded successfully");
        } catch (error: any) {
          ToastService.ShowError(error.message);
        } finally {
          setImageLoading(false);
        }
      }
    },
  };
  const handleDeleteImg = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch({
      type: "SET_DELETED_EVENT_IMAGE",
      payload: "",
    });
  };
  return (
    <div>
      <section className="flex flex-col p-[24px] w-full ">
        <div className="flex items-center justify-between">
          <p className="text-[30px] font-[600] text-text_color">Profile</p>
          <Button
            className="text-white bg-[#6666EA] border-none rounded-[20px]"
            onClick={upadtePersonalInfor}
          >
            Save Changes
          </Button>
        </div>

        <div className="flex w-full gap-6 justify-between">
          <section className="w-[60%] flex flex-col gap-4">
            <div className="mt-5 flex-auto">
              <span className="text-[#0F0F37] ">First name </span>
              <Input
                placeholder="John"
                onChange={(e) => handleInputChange("firstName", e.target.value)}
                value={state.firstName}
                className="noplaceholder h-[48px] border-t-0 border-l-0 border-r-0 border-[#E0E0FB] rounded-[10px] "
              />
            </div>

            <div className="flex-auto">
              <span className="text-[#0F0F37]">Last name</span>
              <Input
                placeholder="Doe"
                onChange={(e) => handleInputChange("lastName", e.target.value)}
                value={state.lastName}
                className="noplaceholder h-[48px] border-t-0 border-l-0 border-r-0 border-[#E0E0FB] rounded-[10px]"
              />
            </div>

            <div className="flex-auto">
              <span className="text-[#0F0F37]">Email</span>
              <Input
                placeholder="hola@soytian.tech"
                value={state.Email}
                disabled
                className="noplaceholder h-[48px] border-t-0 border-l-0 border-r-0 border-[#E0E0FB] rounded-[10px]"
              />
            </div>

            <div className="flex-auto">
              <span className="text-[#0F0F37]">Telephone</span>
              <Input
                placeholder="01234567890"
                onChange={(e) => handleInputChange("telephone", e.target.value)}
                value={state.telephone}
                className="noplaceholder h-[48px] border-t-0 border-l-0 border-r-0 border-[#E0E0FB] rounded-[10px]"
              />
            </div>
          </section>

          <section>
            <div className="bg-white mt-5 border border-[#E0E0FB] sticky top-0 rounded-[20px] p-[24px]">
              <div className="flex items-center justify-center flex-col">
                {!state.image ? (
                  <img
                    src={basicElectronics_img}
                    alt="avatar"
                    className="rounded-full w-[133px] h-[133px]"
                  />
                ) : (
                  <div
                    className="rounded-[10px]"
                    style={{
                      width: "133px",
                      height: "133px",
                      backgroundImage: `url(${state.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                )}
                <p className="text-[#6E6C8E] mt-2 text-center">
                  Add your organisation Logo
                </p>
                <p className="text-[#6E6C8E] mt-2 text-[12px] text-center">
                  The recommended file formats are :<br /> jpg, jpeg, png.
                </p>
              </div>

              <div className="flex items-center justify-center mt-4">
                <Button
                  className="border-none rounded-[20px] text-[#DE3B40]"
                  icon={<RiDeleteBin7Line />}
                  onClick={handleDeleteImg}
                >
                  Remove
                </Button>

                <Upload.Dragger
                  showUploadList={false}
                  {...myprops}
                  className="w-full relative"
                  style={{
                    borderColor: "#E0E0FB",
                    border: "none",
                    background: "white",
                    color: "#5353C2",
                  }}
                >
                  {ImageLoading ? (
                    <p className="bg-green-300 w-full text-center rounded-lg">
                      Uploading...
                    </p>
                  ) : (
                    <div className="flex gap-2 items-center">
                      <p className="text-[#5353C2] rounded-[20px] border border-[#E0E0FB] py-[4px] px-[16px]">
                        Upload
                      </p>
                    </div>
                  )}
                </Upload.Dragger>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div className="flex w-full mt-5 pb-4 pr-4 justify-end">
        {/* Add any other buttons or actions if needed */}
      </div>
    </div>
  );
};

export default MyProfile;
