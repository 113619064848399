import { useEffect, useReducer, useState } from "react";
import { Button, Input, Modal, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { BsPersonGear } from "react-icons/bs";
import BaseService from "../../../helpers/baseServices";
import { FaUserDoctor } from "react-icons/fa6";
import { GiMadScientist, GiNurseFemale, GiFemaleVampire } from "react-icons/gi";
import { GrUserManager } from "react-icons/gr";
import {
  MdOutlineEngineering,
  MdOutlineArchitecture,
  MdOutlineDeveloperMode,
} from "react-icons/md";
import { SiHiveBlockchain, SiAzuredevops } from "react-icons/si";
import ReactDOMServer from "react-dom/server";

interface ModalProps {
  open: boolean;
  roleID: string;
  setOpenSMS: (open: boolean) => void;
  handleReFetch?: any;
}

interface initUI {
  name: string;
  description: string;
  icon: string;
}

type Action =
  | { type: "SET_NAME"; payload: string }
  | { type: "SET_ICON"; payload: string }
  | { type: "SET_DESCRIPTION"; payload: string }
  | { type: "INIT_SETUP"; payload: any };

const reducer = (state: initUI, action: Action) => {
  switch (action.type) {
    case "SET_NAME":
      return { ...state, name: action.payload };
    case "SET_ICON":
      return { ...state, icon: action.payload };
    case "SET_DESCRIPTION":
      return { ...state, description: action.payload };
    case "INIT_SETUP":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const initialState = {
  name: "",
  description: "",
  icon: "",
};

const EditRole = ({ open, roleID, setOpenSMS, handleReFetch }: ModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedIconIndex, setSelectedIconIndex] = useState<number | null>(
    null
  );
  const [icon, setIcon] = useState<string>("");

  const handleCancel = () => {
    setOpenSMS(false);
  };

  const handleIconClick = (ico: any, index: any) => {
    setSelectedIconIndex(index);
    setIcon(ReactDOMServer.renderToString(ico));
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const payload = {
        _id: roleID,
        name: state.name,
        description: state.description,
        icon: icon,
      };
      console.log("Sending payload:", payload);
      const results = await BaseService.put_api(
        "/robocentre/event/roles",
        payload
      );
      handleReFetch();
      setOpenSMS(false);
      // console.log("API results:", results);
    } catch (error: any) {
      console.log("Message sending error:", error.message);
      setOpenSMS(true);
    } finally {
      setLoading(false);
    }
  };

  const getSingleRole = async () => {
    setLoading(true);
    try {
      const results = await BaseService.get_api(
        `/robocentre/event/roles?filter={"_id": {"$$_id": ${JSON.stringify(
          roleID
        )}}}`
      );
      const { payload } = results.data;
      const { name, description, icon } = payload[0];
      // console.log("API payload:", name, description, icon);
      dispatch({ type: "INIT_SETUP", payload: { name, description, icon } });

      // Find the index of the fetched icon in the icons array
      const index = icons.findIndex(
        (ico) => ReactDOMServer.renderToString(ico) === icon
      );

      setSelectedIconIndex(index);
    } catch (error: any) {
      console.log("Message sending error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSingleRole();
  }, [roleID]);

  const handleTitle = (value: string) => {
    dispatch({ type: "SET_NAME", payload: value });
  };

  const handleTextArea = (value: string) => {
    dispatch({ type: "SET_DESCRIPTION", payload: value });
  };

  const icons = [
    <MdOutlineEngineering className="w-10 h-10" />,
    <GiMadScientist className="w-10 h-10" />,
    <MdOutlineArchitecture className="w-10 h-10" />,
    <FaUserDoctor className="w-10 h-10" />,
    <GiNurseFemale className="w-10 h-10" />,
    <GiFemaleVampire className="w-10 h-10" />,
    <SiHiveBlockchain className="w-10 h-10" />,
    <SiAzuredevops className="w-10 h-10" />,
    <GrUserManager className="w-10 h-10" />,
    <MdOutlineDeveloperMode className="w-10 h-10" />,
  ];

  return (
    <>
      <Modal
        width={"35%"}
        open={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        okText="Edit Type"
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "30vh" } }}
      >
        <Spin spinning={loading}>
          <div>
            <div className="flex gap-6 items-center">
              <BsPersonGear
                size={"2em"}
                className="text-[600] text-[#0F0F37]"
              />
              <p className="text-[30px] font-[600] text-[#0F0F37]">Edit Role</p>
            </div>
            <section className="my-6 flex flex-col gap-6">
              <div className="">
                <span className="text-[#0F0F37]">Title</span>
                <Input
                  placeholder="Enter your role"
                  className="noplaceholder h-[48px] rounded-[10px]"
                  value={state.name}
                  onChange={(e) => handleTitle(e.target.value)}
                />
              </div>
              <div className="">
                <span className="text-[#0F0F37]">Description</span>
                <TextArea
                  rows={4}
                  placeholder="Describe your role"
                  className="noplaceholder h-[48px] rounded-[10px]"
                  value={state.description}
                  onChange={(e) => handleTextArea(e.target.value)}
                />
              </div>
              <div className="">
                <span className="text-[#0F0F37]">Icon</span>
                <div className="grid grid-cols-5 gap-2">
                  {icons.map((ico, i) => (
                    <div
                      key={i}
                      className={`cursor-pointer rounded-[10px] bg-[#d9d9d9] p-1 flex justify-center items-center ${
                        selectedIconIndex === i
                          ? "border-[2px] border-solid border-[#6666ea]"
                          : ""
                      }`}
                      onClick={() => handleIconClick(ico, i)}
                    >
                      {ico}
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default EditRole;
