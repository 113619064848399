import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import { GeneralContextProvider } from "./context/generalContext";
import Dashboard from "./pages/dashboard/dashboard";
import EventsPage from "./pages/events/events";
import CreateEventsPage from "./pages/CreateEventsPage/CreateEventsPage";
import EventDetails from "./pages/EventDetails/EventDetails";
import Report from "./pages/Report/Report";
import { useEffect } from "react";
import LocalStorageClear from "./pages/EventDetails/Tabs/Infor/LocalStorageClear";
import EventRole from "./pages/EventRole/EventRole";
import AttendeeDetail from "./pages/EventDetails/Tabs/Attendee/AttendeeDetail/AttendeeDetail";
import { SkeletonTheme } from "react-loading-skeleton";
import RoleFunction from "./pages/RoleFunction/RoleFunction";
import EventFunction from "./pages/EventFunction/EventFunction";
import Signin from "./pages/AuthenticationPage/Signin/Signin";
import ActivateAccount from "./pages/AuthenticationPage/ActivateAccount/ActivateAccount";
import Profile from "./pages/Profile/Profile";
import PageNotfound from "./pages/AuthenticationPage/PageNotFound/PageNotfound";
import ProtectedRoute from "./pages/AuthenticationPage/ProtectedRoute/ProtectedRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//hello
function App() {
  return (
    <>
      <GeneralContextProvider>
        <SkeletonTheme baseColor="#ccccf8" highlightColor="#f2f2fe">
          <Router>
            {/* <LocalStorageClear /> */}
            <ToastContainer />

            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/activate-account" element={<ActivateAccount />} />
              <Route path="/*" element={<PageNotfound />} />
              <Route path="/signin" element={<Signin />} />

              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Outlet />
                  </ProtectedRoute>
                }
              >
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/events" element={<EventsPage />} />
                <Route path="/report" element={<Report />} />
                <Route path="/profile" element={<Profile />} />
                <Route
                  path="event/:id/role_function"
                  element={<RoleFunction />}
                />
                <Route
                  path="event/:id/attendee_type"
                  element={<EventFunction />}
                />
                <Route
                  path="event/:id/attendee_function"
                  element={<EventFunction />}
                />
                <Route
                  path="/events/create_events"
                  element={<CreateEventsPage />}
                />
                <Route path="/events/:id" element={<EventDetails />} />
                <Route path="events/:id/attendee" element={<EventDetails />} />
                <Route
                  path="events/:id/attendee/:param2"
                  element={<AttendeeDetail />}
                />
              </Route>
            </Routes>
          </Router>
        </SkeletonTheme>
      </GeneralContextProvider>
    </>
  );
}

export default App;
