import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ShowError = (message: string) =>
  toast(message || "Error", {
    isLoading: false,
  });

const ShowSuccess = (message?: string) =>
  toast.success(message || "Created Successfully !", {
    position: "top-right",
    autoClose: 5000,
  });

const ShowErrors = (message: string) =>
  toast.error(message || "Error Notification !", {
    position: "top-right",
    isLoading: false,
  });

const ShowWarn = (message: string) =>
  toast.warn(message || "Warning Notification !", {
    position: "top-right",
    isLoading: false,
  });

const ShowInfor = (message: string) =>
  toast.info(message || "Info Notification !", {
    position: "top-right",
    isLoading: false,
  });

const ShowToast = (message: string) =>
  toast("Custom Style Notification with css class!", {
    position: "top-right",
    className: "foo-bar",
    isLoading: false,
  });

export const ToastService = {
  ShowError,
  ShowSuccess,
  ShowErrors,
  ShowWarn,
  ShowInfor,
  ShowToast,
};
