import React, { useEffect, useReducer, useState } from "react";
import { Button, Input, Space, Spin } from "antd";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import PageLayout from "../../../../../layouts/pageLayout";
import avata from "../../../../../assets/images/avata.png";
import BaseService from "../../../../../helpers/baseServices";
import moment from "moment";

interface VerifyUI {
  remarks: string;
  verified: boolean;
}

type Action =
  | { type: "SET_REMARKS"; payload: string }
  | { type: "SET_VERIFY"; payload: boolean }
  | { type: "SET_INIT"; payload: Partial<VerifyUI> };

const reducer = (state: VerifyUI, action: Action): VerifyUI => {
  switch (action.type) {
    case "SET_INIT":
      return { ...state, ...action.payload };
    case "SET_REMARKS":
      return { ...state, remarks: action.payload };
    case "SET_VERIFY":
      return { ...state, verified: action.payload };
    default:
      return state;
  }
};

const initialstate: VerifyUI = {
  remarks: "",
  verified: false,
};

const AttendeeDetail = () => {
  const navigate = useNavigate();
  const { id, param2 } = useParams();
  const [isRemarkVisible, setRemarkVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [postLoading, setPostLoading] = useState<boolean>(false);

  const [getSingleData, setSingleData] = useState<any>([]);
  const [state, dispatch] = useReducer(reducer, initialstate);

  const handleVerifyClick = () => {
    setRemarkVisible(!isRemarkVisible);
  };

  const getSingleUser = async () => {
    setLoading(true);
    try {
      const results = await BaseService.get_api(
        `/robocentre/event/user-registeration-users/${id}?filter={"_id": {"$$_id": ${JSON.stringify(
          param2
        )}}}`
      );
      const { payload } = results.data;
      setSingleData(payload);
      dispatch({
        type: "SET_INIT",
        payload: {
          verified: payload[0]?.verified || false,
        },
      });
      console.log("Payload", payload);
    } catch (error: any) {
      console.log("Message sending error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const postNotification = async () => {
    setPostLoading(true);
    try {
      const payload = {
        ticketId: param2,
        remarks: state.remarks,
        verified: !state.verified,
      };
      console.log("Sending payload:", payload);

      if (!state.verified) {
        await BaseService.post_api(
          "/robocentre/event/user-registeration-single/verify",
          payload
        );
      } else {
        // await BaseService.put_api(
        //   "/robocentre/event/user-registeration-single/unverify",
        //   payload
        // );
      }

      dispatch({ type: "SET_VERIFY", payload: !state.verified });
    } catch (error: any) {
      console.log("Message sending error:", error.message);
    } finally {
      setPostLoading(false);
    }
  };

  useEffect(() => {
    getSingleUser();
  }, [id]);

  const handleRemarksChange = (value: string) => {
    dispatch({ type: "SET_REMARKS", payload: value });
  };

  return (
    <PageLayout eventActive="active">
      <div className="w-full bg-default_bg h-full overflow-y-auto p-5 font-baloo">
        <Spin spinning={loading}>
          <div className="flex items-center">
            <div className="flex items-center mr-auto gap-4">
              <Button
                className="custom-button rounded-[20px] text-[#6666EA] border-1 border-solid border-[#6666EA]"
                icon={<FaArrowLeft />}
                onClick={() => navigate(`/events/${id}/attendee`)}
              >
                Back
              </Button>
              <p className="text-[32px] font-[700] text-text_color">
                Attendee Details
              </p>
            </div>
          </div>

          <div className="mt-10">
            <section className="flex gap-4">
              <div className="w-[70%] flex flex-col gap-6">
                <article className="rounded-[20px] p-4 bg-white drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)]">
                  <p className="text-[21px] mb-6 text-text_color font-[500]">
                    Personal Info
                  </p>
                  <section className="grid grid-cols-2 bg-white">
                    <div className="flex flex-col gap-3">
                      <p className="flex flex-col">
                        <span className="text-[#6E6C8E]">First name </span>
                        <span>{getSingleData[0]?.firstName}</span>
                      </p>
                      <p className="flex flex-col">
                        <span className="text-[#6E6C8E]">Email</span>
                        <span className="text-[#0F0F37] font-[400] text-[16px]">
                          <span>{getSingleData[0]?.email}</span>
                        </span>
                      </p>
                      <p className="flex flex-col">
                        <span className="text-[#6E6C8E]">
                          Organization Name
                        </span>
                        <span>
                          {getSingleData[0]?.jobFucntionData?.name || "N/A"}
                        </span>
                      </p>
                      <p className="flex flex-col">
                        <span className="text-[#6E6C8E]">Registered</span>
                        <p className="flex gap-2">
                          <span>
                            {moment(getSingleData[0]?.createdAt).format(
                              "Do MMM YYYY"
                            )}
                          </span>{" "}
                          |
                          <span>
                            {moment(getSingleData[0]?.createdAt).format(
                              "hh:mm A"
                            )}
                          </span>
                        </p>
                      </p>
                    </div>
                    <div className="flex flex-col gap-3">
                      <p className="flex flex-col">
                        <span className="text-[#6E6C8E]">Last name </span>
                        <span>{getSingleData[0]?.lastName}</span>
                      </p>

                      <p className="flex flex-col">
                        <span className="text-[#6E6C8E]">Telephone </span>
                        <span>{getSingleData[0]?.phone || "N/A"}</span>
                      </p>
                      <p className="flex flex-col">
                        <span className="text-[#6E6C8E]">Job Title</span>
                        <span>{getSingleData[0]?.roleData?.name || "N/A"}</span>
                      </p>
                      <p className="flex flex-col">
                        <span className="text-[#6E6C8E]">Verified</span>
                        <span>{getSingleData[0]?.phone || "N/A"}</span>
                      </p>
                    </div>
                  </section>
                </article>
                <article className="rounded-[20px] p-4 bg-white drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)]">
                  <p className="text-[21px] mb-6 text-text_color font-[500]">
                    Professional Information{" "}
                  </p>
                  <section className="grid grid-cols-2 bg-white">
                    <div className="flex flex-col gap-3">
                      <p className="flex flex-col">
                        <span className="text-[#6E6C8E]">
                          Medical specialty{" "}
                        </span>
                        <span>{getSingleData[0]?.firstName}</span>
                      </p>
                      <p className="flex flex-col">
                        <span className="text-[#6E6C8E]">Describe</span>
                        <span>
                          {getSingleData[0]?.roleData?.description || "N/A"}
                        </span>
                      </p>
                      <p className="flex flex-col">
                        <span className="text-[#6E6C8E]">
                          Organization Name
                        </span>
                        {getSingleData[0]?.jobFucntionData?.description ||
                          "N/A"}
                      </p>
                    </div>
                    <div className="flex flex-col gap-3">
                      <p className="flex flex-col">
                        <span className="text-[#6E6C8E]">Last name </span>
                        <span>{getSingleData[0]?.lastName}</span>
                      </p>
                    </div>
                  </section>
                </article>
              </div>
              <div className="w-[300px]">
                <section className="bg-white drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] sticky top-0 rounded-[20px] p-[24px]">
                  <div>
                    <div className="flex items-center justify-center">
                      <img
                        src={avata}
                        alt=""
                        className="rounded-full w-[133px] h-[133px]"
                      />
                    </div>
                    <p className="text-[#0F0F37] text-[21px] mt-3 font-[600] text-center">
                      Jonathan Sika Doe
                    </p>
                    <p className="text-[#6F7787] font-[600] text-center">
                      Technical Director{" "}
                    </p>
                    <p className="text-[#0F0F37] font-[600] text-center">
                      Anfield Medicals{" "}
                    </p>
                  </div>
                  <div className="flex w-full flex-col gap-4 justify-center   items-center mt-6">
                    <Button
                      className="custom-button text-white bg-[#6666EA] border-none drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[20px] py-4"
                      onClick={handleVerifyClick}
                    >
                      {state.verified ? "Unverify User" : "Verify Attendee"}
                    </Button>
                    <Space.Compact
                      className={`drop-shadow-[10px_3px_10px_rgba(102,102,234,0.1)]  h-[48px] overflow-hidden transition-all duration-500 ${
                        isRemarkVisible
                          ? "max-h-[150px] opacity-100 mt-4"
                          : "max-h-0 opacity-0"
                      }`}
                    >
                      <Input
                        className="border border-[#E0E0FB]"
                        placeholder="Add a Remark"
                        defaultValue={"User validated"}
                        onChange={(e) => handleRemarksChange(e.target.value)}
                      />
                      <div
                        style={{
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                          background: "rgba(119, 209, 164, 0.1)",
                        }}
                        className="border border-[#E0E0FB] p-2 text-[#77D1A4] cursor-pointer flex justify-center items-center"
                        onClick={postNotification}
                      >
                        {state.verified ? "Unverify" : "Verify"}
                      </div>
                    </Space.Compact>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </Spin>
      </div>
    </PageLayout>
  );
};

export default AttendeeDetail;
