import { Button, Dropdown, Input, MenuProps, Select, Spin } from "antd";
import PageLayout from "../../layouts/pageLayout";
import { CiEdit, CiSearch } from "react-icons/ci";
import { IoIosArrowDropright, IoIosPeople, IoMdMore } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import useTableSyle from "../EventDetails/Tabs/Attendee/useTableStyle";
import { FiPlus } from "react-icons/fi";
import { FaArrowLeft } from "react-icons/fa";
import { useEffect, useState } from "react";
import { BiMessageRoundedDots } from "react-icons/bi";
import BaseService from "../../helpers/baseServices";
import moment from "moment";
import AddFunction from "./Modal/AddFunction";
import EditFunction from "./Modal/EditFunction";

const EventFunction = () => {
  const [openSMS, setOpenSMS] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [Allroles, setAllRoles] = useState<any>();
  const [EditOpen, setEditOpen] = useState<boolean>(false);
  const [roleID, setRoleID] = useState<any>();
  const navigate = useNavigate();
  const [customStyles] = useTableSyle();
  const { id } = useParams();

  const GetAllRoles = async () => {
    setLoading(true);
    try {
      const results = await BaseService.get_api(
        "/robocentre/event/job-function"
      );
      console.log("Event function : ", results.data.payload);
      setAllRoles(results.data.payload);
    } catch (error: any) {
      console.log("error message is", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMenuClick = (e: any, rowID: string) => {
    if (e.key === "1") {
      setEditOpen(true);
      setRoleID(rowID);
    }
  };

  const columns: any = [
    {
      name: "Event Name",
      cell: (row: any) => row?.eventsData?.name,

      width: "20%",
    },
    {
      name: "Function Name",
      cell: (row: any) => row?.name,
      width: "20%",
    },
    {
      name: "Description",
      cell: (row: any) => row?.description,
    },
    {
      name: "Date Created",
      width: "15%",
      cell: (row: any) =>
        moment(row?.createdAt).format("Do MMM YYYY - hh:mm A"),
    },
    {
      name: "",
      cell: (row: any) => {
        const menuItems: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <span className="text-default_ash text-sm">Edit Function</span>
            ),
            icon: <CiEdit size={"1.3em"} />,
            onClick: (e) => handleMenuClick(e, row._id),
          },
        ];
        return (
          <Dropdown menu={{ items: menuItems }} className="text-[14px]">
            <IoMdMore size={"2em"} className="text-[#6D31ED]" />
          </Dropdown>
        );
      },
      width: "6%",
    },
  ];

  useEffect(() => {
    GetAllRoles();
  }, [refresh]);
  return (
    <>
      <div className="w-full bg-default_bg h-full overflow-y-auto p-5">
        <div className="flex items-center gap-4"></div>
        <div className="bg-white p-4  rounded-[20px] drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] mt-10">
          <section className="flex items-center justify-between">
            <div className="flex items-center  m-3  gap-4">
              <div className="flex justify-between py-6   bg-white rounded-[10px]">
                <div className=" w-full  flex items-center gap-4">
                  <div>
                    <Input
                      size="large"
                      placeholder="Search"
                      className="noplaceholder rounded-[20px] border-[1px] border-[#9494f5] text-[#9494f5]"
                      suffix={<CiSearch />}
                    />
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <section className="flex">
              <div className="flex gap-4 ml-auto mr-4 items-center">
                <div
                  className="text-white flex gap-3 items-center justify-center py-2 px-3 bg-default_blue rounded-full cursor-pointer"
                  onClick={() => setOpenSMS(true)}
                >
                  <FiPlus size={"1.6em"} />
                  <span>Add Function</span>
                </div>
              </div>
            </section>
          </section>

          <DataTable
            className="mt-4"
            columns={columns}
            data={Allroles}
            paginationServer
            progressPending={isLoading}
            progressComponent={<Spin />}
            pagination
            // paginationTotalRows={total}
            pointerOnHover
            highlightOnHover
            customStyles={customStyles}
            // onRowClicked={(selected: any) =>
            //   navigate(`/vEducators/${selected._id}`)
            // }
          />
        </div>
      </div>
      {openSMS && (
        <AddFunction
          open={openSMS}
          setOpenSMS={setOpenSMS}
          id={id}
          handleReFetch={() => setRefresh(!refresh)}
        />
      )}
      {EditOpen && (
        <EditFunction
          open={EditOpen}
          setOpenSMS={setEditOpen}
          roleID={roleID}
          handleReFetch={() => setRefresh(!refresh)}
        />
      )}
    </>
  );
};

export default EventFunction;
