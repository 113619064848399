import family from "../../assets/images/family.png";
import { SlCalender } from "react-icons/sl";
import { GoLocation } from "react-icons/go";
import { MdOutlineMoreVert } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Avatar } from "antd";
import { styled } from "@mui/system";
import AvatarGroup from "@mui/material/AvatarGroup/AvatarGroup";

const CustomAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
  "& .MuiAvatar-root": {
    border: "2px solid white",
    marginLeft: "-5px",
    color: "#6666EA",
    background: "#F9F9FF",
    zIndex: "10",
  },
  "& .MuiAvatarGroup-avatar": {
    width: "32px",
    height: "32px",
    fontSize: "12px",
    marginLeft: "-12px",
    border: "2px solid white",
  },
}));

const DashboardEventCard = ({ image }: { image?: any }) => {
  const navigate = useNavigate();

  return (
    <div
      className="bg-white cursor-pointer  hover:drop-shadow-none drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] px-[10px] pt-[10px] rounded-[20px] pb-10"
      onClick={() => navigate("/events/weuyytewytey")}
    >
      <img
        src={image || family}
        alt="avatar"
        className="rounded-[10px]"
        style={{ width: "100%", height: "184px" }}
      />
      <div className="px-[10px]">
        <p className="font-[600] text-[#201E5A] mt-6 mb-[16px]">
          2024 HCOWA WEST AFRICA MEDICAL INDUSTRY INVESTMENT SUMMIT AND EXPO
        </p>
        <div className="flex pb-[8px] items-center gap-2 text-[16px] font-[400] text-[#6666EA]">
          <SlCalender size={"16px"} />
          <p>Monday, June 06 | 06:00 PM</p>
        </div>
        <div className="flex items-center gap-2 pb-[16px] font-[400] text-[#6F7787]">
          <GoLocation />
          <p>Accra International Conference Centre</p>
        </div>
        <div className="flex items-center justify-between">
          <div className=" flex items-center  gap-4">
            <p className="bg-[#35A7FF] rounded-[10px] w-[100px] text-center text-white">
              20 Attending
            </p>
            <div className=" flex items-center justify-center">
              <CustomAvatarGroup max={4}>
                <Avatar alt="Remy Sharp" src={family} />
                <Avatar alt="Travis Howard" src={family} />
                <Avatar alt="Agnes Walker" src={family} />
                <Avatar alt="Trevor Henderson" src={family} />
                <Avatar alt="Trevor Henderson" src={family} />
              </CustomAvatarGroup>
            </div>
          </div>
          <MdOutlineMoreVert size={"24px"} className="text-[#6666EA]" />
        </div>
      </div>
    </div>
  );
};

export default DashboardEventCard;
