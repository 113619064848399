export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const COOKIES_DOMAIN = process.env.REACT_APP_COOKIES_DOMAIN;
export const ACCOUNTS_URL = process.env.REACT_APP_ACCOUNTS_URL;
export const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL;
export const CRYPTO_KEY = process.env.REACT_APP_CRYPTO;

export const COOKIES_TOKEN_NAME = "access_token";
export const COOKIES_USER_DATA = "user_data";
export const COOKIES_USER_VERIFIED = "verified";
export const MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
