import React from "react";
import { BiBorderRadius } from "react-icons/bi";

const useTableSyle = () => {
  const customStyles = {
    rows: {
      style: {
        minHeight: "50px",
        // borderRadius: "20px !important",
        // marginBottom: "10px !important",
        // marginTop: "6px !important",
        background: "white",
        // border: "none !important",
        // boxShadow: "0 2px 4px rgba(0,0,0,0.1) !important",
        "&:hover": {
          backgroundColor: "#F9F9FF !important",
        },
      },
    },
    headCells: {
      style: {
        paddingLeft: "6px",
        paddingRight: "6px",
      },
    },
    cells: {
      style: {
        paddingLeft: "6px",
        paddingRight: "6px",
      },
    },
  };

  return [customStyles];
};

export default useTableSyle;
