import PageLayout from "../../layouts/pageLayout";
import {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
  useEffect,
} from "react";
import { IoIosArrowDropright } from "react-icons/io";
import { Button } from "antd";
import { FaArrowLeft } from "react-icons/fa";
import UploadCover from "../EventDetails/Tabs/UploadCover/UploadCover";
import { useNavigate } from "react-router-dom";

const CreateEventsPage = () => {
  const [ImageLoading, setImageLoading] = useState(false);
  const navigate = useNavigate();
  const uploadCoverRef = useRef<any>(null);

  const handleSaveDraft = () => {
    if (uploadCoverRef.current) {
      uploadCoverRef.current.CreateEvent();
    }
  };

  const handleImageLoading = useCallback((isLoading: boolean) => {
    setImageLoading(isLoading);
  }, []);

  useEffect(() => {
    if (ImageLoading) navigate("/events");
  }, [ImageLoading]);

  return (
    <>
      <PageLayout eventActive="active">
        <div className="w-full bg-default_bg h-full overflow-y-auto p-5 font-baloo">
          <div className="flex items-center">
            <div className="flex items-center mr-auto gap-4">
              <Button
                className="custom-button rounded-[20px] text-[#6666EA] border-1 border-solid border-[#6666EA] "
                icon={<FaArrowLeft />}
                onClick={() => navigate("/events")}
              >
                Back
              </Button>
              <p className="text-[32px] font-[700] text-text_color">
                Create Event
              </p>
            </div>

            <Button
              icon={<IoIosArrowDropright />}
              className="custom-button text-white bg-[#6666EA] rounded-[20px] py-4"
              onClick={handleSaveDraft}
            >
              Save
            </Button>
          </div>

          <div className=" flex items-center justify-center">
            <div className="w-[80%]">
              <UploadCover
                ref={uploadCoverRef}
                onImageLoading={handleImageLoading}
              />{" "}
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default CreateEventsPage;
