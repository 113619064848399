import dashOnes from "../../assets/images/dashOnes.svg";
import dashTwo from "../../assets/images/dashTwos.svg";
import dashThree from "../../assets/images/dashThrees.svg";

const DashboardCards = () => {
  return (
    <div className="w-full  gap-[20px] h-[218px] grid grid-cols-3 mt-3">
      <div className="rounded-2xl py-[16px] drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] text-[#252363] px-[24px] bg-white">
        <div className="p-3 text-text_primary">
          <p className="text-[16px] font-[400]">Total Event </p>
          <div className="pt-2  items-center flex justify-between">
            <p className="text-[32px] font-[700]">346</p>
            <div
              className="h-[75px] w-[75px] rounded-[5px] flex justify-center items-center bg-secondary_green bg-opacity-[10%] text-secondary_green"
              style={{
                backgroundImage: `url(${dashOnes})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
              }}
            ></div>
          </div>

          <div className="mt-2.5">
            <p className="text-[14px] font-[400]">
              <span className="text-[#15C2A5] mr-2">8.5% &uarr;</span> Than Last
              Week{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="rounded-2xl drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] text-[#252363] py-[16px] px-[24px] bg-white ">
        <div className="p-3 text-text_primary">
          <p className="text-[16px] font-[400]">Total Attendee</p>
          <div className="pt-2  items-center flex justify-between">
            <p className="text-[32px] font-[700]">124</p>
            <div
              className="h-[75px] w-[75px] rounded-[5px] flex justify-center items-center bg-secondary_green bg-opacity-[10%] text-secondary_green"
              style={{
                backgroundImage: `url(${dashTwo})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
              }}
            ></div>
          </div>

          <div className="mt-2.5">
            <p className="text-[14px] font-[400]">
              <span className="text-[#15C2A5] mr-2">8.5% &uarr;</span> Than Last
              Week{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="rounded-2xl drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] text-[#252363] py-[16px] px-[24px] bg-white ">
        <div className="p-3 text-text_primary">
          <p className="text-[16px] font-[400]">Events Completed</p>
          <div className="pt-2  items-center flex justify-between">
            <p className="text-[32px] font-[700]">122</p>
            <div
              className="h-[75px] w-[75px] rounded-[5px] flex justify-center items-center bg-secondary_green bg-opacity-[10%] text-secondary_green"
              style={{
                backgroundImage: `url(${dashThree})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "10px",
              }}
            ></div>
          </div>

          <div className="mt-2.5">
            <p className="text-[14px] font-[400]">
              <span className="text-[#15C2A5] mr-2">8.5% &uarr;</span> Than Last
              Week{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCards;
