import PageLayout from "../../layouts/pageLayout";
import DashImg from "../../assets/images/DashImgs.svg";
import { Avatar, Button, Progress } from "antd";
import { IoIosArrowRoundForward } from "react-icons/io";
import family from "../../assets/images/family.png";
import party from "../../assets/images/party.png";
import draftOne from "../../assets/images/draftPicOne.png";
import draftTwo from "../../assets/images/draftPictwo.png";
import popping from "../../assets/images/popping.svg";

import { styled } from "@mui/system";
import AvatarGroup from "@mui/material/AvatarGroup/AvatarGroup";
import DashboardCards from "./dashboardCards";
import DashboardEventCard from "./DashboardEventCard";

const CustomAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
  "& .MuiAvatar-root": {
    border: "2px solid white",
    marginLeft: "-5px",
    color: "#6666EA",
    background: "#F9F9FF",
    zIndex: "10",
  },
  "& .MuiAvatarGroup-avatar": {
    width: "32px",
    height: "32px",
    fontSize: "12px",
    marginLeft: "-12px",
    border: "2px solid white",
  },
}));

const Dashboard = () => {
  return (
    <PageLayout dashActive="active">
      <div className="w-full bg-default_bg h-full overflow-y-auto p-5">
        <p className="text-[32px] font-[700] text-text_color">Dashboard</p>
        <DashboardCards />
        <section className="grid grid-cols-3 mt-[40px] gap-4">
          <div className="col-span-2">
            <h1 className="text-[#0F0F37] font-[600] text-[30px]">
              Ongoing Events
            </h1>
            <div className="flex bg-white p-3 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[10px] items-center w-full gap-4">
              <div
                className="w-[162px] h-[120px]"
                style={{
                  backgroundImage: `url(${DashImg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "10px",
                }}
              >
                {/* <img src={} alt="" className="rounded-[20px]" />{" "} */}
              </div>
              <div className="flex-auto">
                <p className="text-[21px] text-[#252363]">
                  Abstract Colors Workshop
                </p>
                <p className="text-[#9D9CC2]">
                  Accra international conference centre
                </p>
                <div className="flex items-center mt-4 gap-4">
                  <p className="bg-[#35A7FF] rounded-[10px] w-[100px] text-center text-white">
                    23 Attending
                  </p>
                  <div className="flex items-center justify-center">
                    <CustomAvatarGroup max={4}>
                      <Avatar alt="Remy Sharp" src={family} />
                      <Avatar alt="Travis Howard" src={family} />
                      <Avatar alt="Agnes Walker" src={family} />
                      <Avatar alt="Trevor Henderson" src={family} />
                      <Avatar alt="Trevor Henderson" src={family} />
                    </CustomAvatarGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-[#0F0F37] font-[600] text-[30px] my-6">
                Upcoming Events
              </p>
              <Button
                className="custom-button rounded-[20px] text-[#6666EA] bg-[#f9f9ff] border-none"
                icon={<IoIosArrowRoundForward />}
                iconPosition={"end"}
                // onClick={() => navigate("/events")}
              >
                see all
              </Button>
            </div>
            <section className="grid grid-cols-2 gap-4 mb-20">
              <DashboardEventCard image={party} />
              <DashboardEventCard image={popping} />
            </section>
          </div>
          <div className="col-span-1">
            <div className="min-h-[270px] text-[#252363] p-[24px] drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] rounded-[20px] bg-white">
              <div className="text-[30px] font-[600]">Drafted Events</div>
              <div className="flex items-center w-full gap-4">
                <div className="w-[60px]">
                  <img
                    src={draftOne}
                    alt=""
                    className="rounded-[20px]"
                    style={{ width: "60px", height: "60px" }}
                  />
                </div>
                <div className="flex-auto">
                  <p>Curator workshop</p>
                  <Progress
                    percent={60}
                    strokeColor="#6666EA"
                    size="small"
                    showInfo={false}
                  />
                  <p className="text-[12px] text-[#9D9CC2]">70 % Complete</p>
                </div>
              </div>
              <div>
                <div className="flex items-center w-full mt-4 gap-4">
                  <div className="w-[60px]">
                    <img
                      src={draftTwo}
                      alt=""
                      className="rounded-[20px]"
                      style={{ width: "60px", height: "60px" }}
                    />
                  </div>
                  <div className="flex-auto">
                    <p>Curator workshop</p>
                    <Progress
                      percent={50}
                      strokeColor="#6666EA"
                      size="small"
                      showInfo={false}
                    />
                    <p className="text-[12px] text-[#9D9CC2]">30 % Complete</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </PageLayout>
  );
};

export default Dashboard;
