import PageLayout from "../../layouts/pageLayout";
import { useEffect, useRef, useState } from "react";
import { IoIosArrowDropright } from "react-icons/io";
import { Button } from "antd";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Attendee from "../EventDetails/Tabs/Attendee/Attendee";
import Infor from "../EventDetails/Tabs/Infor/Infor";
import LocationNTime from "../EventDetails/Tabs/LocationNTime/LocationNTime";
import Policy from "../EventDetails/Tabs/Policy/Policy";
import Ticket from "../EventDetails/Tabs/Ticket/Ticket";
import EventRole from "../EventRole/EventRole";
import EventFunction from "../EventFunction/EventFunction";

const RoleFunction = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const [activeTab, setActiveTab] = useState(0);

  const tabNames = ["Attendee Type", "Attendee Function"];

  const Tabs = [<EventRole />, <EventFunction />];

  return (
    <PageLayout eventActive="active">
      <div className="w-full bg-default_bg h-full overflow-y-auto p-5 font-baloo">
        <div className="flex items-center">
          <div className="flex items-center mr-auto gap-4">
            <Button
              className="custom-button rounded-[20px] text-[#6666EA] border-1 border-solid border-[#6666EA] "
              icon={<FaArrowLeft />}
              onClick={() => navigate(`/events/${id}/attendee`)}
            >
              Back
            </Button>
          </div>
        </div>

        <div className="mt-[30px]">
          <div className="flex items-center gap-2">
            {tabNames.map((d, i) => (
              <div
                key={i}
                className={`${
                  activeTab === i
                    ? "bg-gradient-to-tr from-default_blue to-light_blue text-white"
                    : "bg-white text-[#252363]"
                } px-6 py-2 text-[16px] font-[300] drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] hover:drop-shadow-none rounded-[20px] cursor-pointer`}
                onClick={() => setActiveTab(i)}
              >
                <span>{d}</span>
              </div>
            ))}
          </div>
          <div>{Tabs[activeTab]}</div>
        </div>
      </div>
    </PageLayout>
  );
};

export default RoleFunction;
