import { Button, Input, Spin } from "antd";
import { useEffect, useReducer, useState } from "react";
import { IoPersonCircle } from "react-icons/io5";
import BaseService from "../../../../helpers/baseServices";
import { ToastService } from "../../../../components/shared/ToastNotifications/ToastNotifications";

interface AuthUI {
  firstName: string;
  lastName: string;
  Email: string;
  telephone: string;
}

type Action =
  | { type: "SET_FIRSTNAME"; payload: string }
  | { type: "SET_LASTNAME"; payload: string }
  | { type: "SET_EMAIL"; payload: string }
  | { type: "SET_TELEPHONE"; payload: string }
  | { type: "SET_INIT"; payload: AuthUI };

const reducer = (state: AuthUI, action: Action) => {
  switch (action.type) {
    case "SET_FIRSTNAME":
      return { ...state, firstName: action.payload };
    case "SET_LASTNAME":
      return { ...state, lastName: action.payload };
    case "SET_EMAIL":
      return { ...state, Email: action.payload };
    case "SET_TELEPHONE":
      return { ...state, telephone: action.payload };
    case "SET_INIT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
const initState = {
  firstName: "",
  lastName: "",
  Email: "",
  telephone: "",
};

const PersonalInformation = ({
  onRefreshHandler,
  Mainstate: { firstName, lastName, Email, telephone, gender },
  getloading,
  setTab,
}: {
  onRefreshHandler: any;
  Mainstate: any;
  getloading: any;
  setTab: any;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, initState);
  const [refresh, setRefresh] = useState<boolean>(false);
  const upadtePersonalInfor = async () => {
    setLoading(true);
    try {
      const payload = {
        firstName: state.firstName,
        lastName: state.lastName,
        phone: state.telephone,
        gender,
      };
      console.log("Sending payload:", payload);
      const results = await BaseService.put_api(
        "/robocentre/user/details",
        payload
      );
      setRefresh((prev) => !prev);
      ToastService.ShowSuccess("Updated Successfully !");
      // console.log("API results:", results);
    } catch (error: any) {
      console.log("Message sending error:", error.message);
      ToastService.ShowError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSaveChanges = () => {
    upadtePersonalInfor();
    onRefreshHandler(refresh);
  };

  const onTabChanges = () => {
    setTab(1);
  };

  useEffect(() => {
    dispatch({
      type: "SET_INIT",
      payload: { firstName, lastName, Email, telephone },
    });
  }, [getloading]);

  const handleFirstname = (firstname: string) => {
    dispatch({
      type: "SET_FIRSTNAME",
      payload: firstname.trim(),
    });
  };
  const handleLastname = (lastname: string) => {
    dispatch({
      type: "SET_LASTNAME",
      payload: lastname.trim(),
    });
  };
  const handlePhone = (phone: string) => {
    dispatch({
      type: "SET_TELEPHONE",
      payload: telephone.trim(),
    });
  };

  return (
    <div>
      <Spin spinning={getloading}>
        <section className="flex flex-col p-[24px] w-full">
          <div className="flex items-center">
            <div className="flex items-center mr-auto gap-4">
              <Button
                className="border-none rounded-[20px] text-[#6666EA] "
                icon={<IoPersonCircle size={"2em"} />}
              ></Button>
              <p className="text-[30px] font-[600] text-text_color">
                Personal info{" "}
              </p>
            </div>
          </div>
          <span className="text-[#6F7787]">Add your personal info</span>
          <div className="mt-5 flex-auto">
            <span className="text-[#0F0F37]">First name </span>
            <Input
              placeholder="John"
              onChange={(e) => handleFirstname(e.target.value)}
              value={state.firstName}
              className="noplaceholder h-[48px] border border-[#E0E0FB] rounded-[10px] "
            />
          </div>

          <div className="mt-5 flex-auto">
            <span className="text-[#0F0F37]">Last name</span>
            <Input
              placeholder="Doe"
              onChange={(e) => handleLastname(e.target.value)}
              value={state.lastName}
              className="noplaceholder  border border-[#E0E0FB]  h-[48px] rounded-[10px] "
            />
          </div>
          <div className="mt-5 flex-auto">
            <span className="text-[#0F0F37]">Email</span>
            <Input
              placeholder="hola@soytian.tech"
              value={state.Email}
              disabled
              className="noplaceholder  border border-[#E0E0FB]  h-[48px] rounded-[10px] "
            />
          </div>
          <div className="mt-5 flex-auto">
            <span className="text-[#0F0F37]">Telephone</span>
            <Input
              placeholder="01234567890"
              onChange={(e) => handlePhone(e.target.value)}
              value={state.telephone}
              className="noplaceholder  border border-[#E0E0FB]  h-[48px] rounded-[10px] "
            />
          </div>
          <div className="flex w-full mt-5 justify-end gap-4">
            <Button
              className="custom-button rounded-[10px] py-[8px] px-[50px] text-white bg-[#6666EA] border-1 border-solid border-[#6666EA]"
              onClick={onSaveChanges}
            >
              save changes
            </Button>
            <Button
              className="custom-button rounded-[10px] py-[8px] px-[50px] text-white bg-[#6666EA] border-1 border-solid border-[#6666EA]"
              onClick={onTabChanges}
            >
              next
            </Button>
          </div>
        </section>
      </Spin>
    </div>
  );
};

export default PersonalInformation;
