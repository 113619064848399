import PageLayout from "../../layouts/pageLayout";
import { useEffect, useRef, useState } from "react";
import { IoIosArrowDropright } from "react-icons/io";
import { Button, Select, Spin } from "antd";
import { FaArrowLeft } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LocationNTime from "./Tabs/LocationNTime/LocationNTime";
import Attendee from "./Tabs/Attendee/Attendee";
import Ticket from "./Tabs/Ticket/Ticket";
import Infor from "./Tabs/Infor/Infor";
import Policy from "./Tabs/Policy/Policy";
import BaseService from "../../helpers/baseServices";
import moment from "moment";

const EventDetails = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const uploadCoverRef = useRef<any>(null);
  const LocationRef = useRef<any>(null);
  const PolicyRef = useRef<any>(null);

  const [activeTab, setActiveTab] = useState(0);
  const [Policyy, setPolicyy] = useState<string>();
  const [namee, setName] = useState<string>();
  const [startData, setStartDate] = useState<string>();
  const [EndData, setEndDate] = useState<string>();
  const [statuss, setStatuss] = useState<string>();
  const [refresh, setRefreh] = useState<boolean>(false);

  const handleSaveDraft = () => {
    console.log("clicked to save");
    if (uploadCoverRef.current) {
      uploadCoverRef.current.updateEvents();
    }
    if (LocationRef.current) {
      LocationRef.current.updateEventsLocation();
    }
    if (PolicyRef.current) {
      PolicyRef.current.updatePolicy();
    }
  };

  const GetEventData = async () => {
    setLoading(true);
    try {
      const results = await BaseService.get_api(
        `/robocentre/event/event-single/${id}`
      );
      const { payload } = results.data;
      const { name, status, eventStartDateTime, eventEndDateTime, policy } =
        payload;
      setPolicyy(policy);
      setName(name);
      setStartDate(eventStartDateTime);
      setEndDate(eventEndDateTime);
      setStatuss(status);
    } catch (error) {
      console.log("This is the error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetEventData();
  }, [refresh]);

  const tabNames = [
    "General info",
    "Location and time",
    // "Ticket",
    "Attendee",
    "Policy",
  ];

  const Tabs = [
    <Infor ref={uploadCoverRef} id={id} />,
    <LocationNTime ref={LocationRef} id={id} />,
    // <Ticket />,
    <Attendee />,
    <Policy ref={PolicyRef} setPolicy={Policyy} myStatus={statuss} id={id} />,
  ];

  const handleTabClick = (index: number, tabName: string) => {
    setActiveTab(index);
    if (tabName === "Attendee") {
      navigate(`/events/${id}/attendee`);
    } else {
      navigate(`/events/${id}`);
    }
  };

  useEffect(() => {
    if (location.pathname.endsWith("/attendee")) {
      setActiveTab(2);
    }
  }, [location.pathname]);

  const handleOnlineFilter = async (selected: string) => {
    setLoading(true);
    try {
      const payload = {
        _id: id,
        status: selected,
      };
      const results = await BaseService.put_api(
        "/robocentre/event/event",
        payload
      );
      setRefreh((prev) => !prev);
    } catch (error) {
    } finally {
      setLoading(false);
      // props.onImageLoading(false);
    }
  };

  const handlePreview = () => {
    const previewUrl = `https://test.events.robocentregh.com/${id}`;
    window.open(previewUrl, "_blank");
  };
  return (
    <PageLayout eventActive="active">
      <div className="w-full bg-default_bg h-full overflow-y-auto p-5 font-baloo">
        <div className="flex items-center">
          <div className="flex items-center mr-auto gap-4">
            <Button
              className="custom-button rounded-[20px] text-[#6666EA] border-1 border-solid border-[#6666EA]"
              icon={<FaArrowLeft />}
              onClick={() => navigate("/events")}
            >
              Back
            </Button>
            <p className="text-[32px] font-[700] text-text_color">
              Create Event
            </p>
          </div>
          <Button
            className="custom-button text-[#6666EA] border-1 border-solid border-[#6666EA] mr-3 rounded-[20px] py-4"
            onClick={handlePreview}
          >
            Preview Event
          </Button>
          <Button
            className="custom-button text-white bg-[#6666EA] rounded-[20px] py-4"
            onClick={handleSaveDraft}
            icon={<IoIosArrowDropright />}
          >
            Save Changes
          </Button>
        </div>

        <div className="mt-[30px]">
          <div className="flex items-center gap-2">
            {tabNames.map((d, i) => (
              <div
                key={i}
                className={`${
                  activeTab === i
                    ? "bg-gradient-to-tr from-default_blue to-light_blue text-white"
                    : "bg-white text-[#252363]"
                } px-6 py-2 text-[16px] font-[300] drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] hover:drop-shadow-none rounded-[20px] cursor-pointer`}
                onClick={() => handleTabClick(i, d)}
              >
                <span>{d}</span>
              </div>
            ))}
          </div>
          <section className="flex gap-4">
            <div className="w-[80%]">{Tabs[activeTab]}</div>
            <div className="w-[300px]">
              <Spin spinning={isLoading}>
                <section className="mt-10 bg-white drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] sticky top-0 rounded-[10px] p-[24px]">
                  <div>
                    <p className="text-[#201E5A] font-[600] mb-5">{namee}</p>
                    <p className="text-[#6F7787] font-[400] mb-2">Start Date</p>
                    <p className="font-[600] text-[#0F0F37]">
                      {moment(startData).format("Do MMM YYYY")} |{" "}
                      {moment(startData).format("hh:mm A")}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#6F7787] font-[400] mt-6">End Date</p>
                    <p className="font-[600] mb-4 text-[#0F0F37]">
                      {moment(EndData).format("Do MMM YYYY")} |{" "}
                      {moment(EndData).format("hh:mm A")}{" "}
                    </p>
                  </div>
                  <p className="w-full h-[1px] bg-[#C2C2F7]"></p>
                  <div className="flex items-center justify-between mt-4">
                    <p className="flex flex-col">
                      <span className="font-[600] text-[#0F0F37]">Status</span>
                      <span className="text-[#6F7787] font-[400]">{`${
                        statuss === "DRAFTED" ? "not Live" : "Live"
                      }`}</span>
                    </p>
                    <Select
                      className={`${
                        statuss === "DRAFTED"
                          ? "DraftedsSelect"
                          : "PublishsSelect"
                      } h-[40px] `}
                      options={[
                        { value: "PUBLISHED", label: "Published" },
                        { value: "DRAFTED", label: "Drafted" },
                      ]}
                      value={statuss}
                      placeholder="Draft / Publish"
                      onChange={(selected) => handleOnlineFilter(selected)}
                      allowClear
                    />
                  </div>
                </section>
              </Spin>
            </div>
          </section>
        </div>
      </div>
    </PageLayout>
  );
};

export default EventDetails;
