import React, { useEffect, useRef, useState } from "react";
import {
  APIProvider,
  Marker,
  Map,
  useMarkerRef,
  MapMouseEvent,
} from "@vis.gl/react-google-maps";

interface LocationMapProps {
  setDispatch: React.Dispatch<any>;
  mylocation?: any;
}

const LocationMap: React.FC<LocationMapProps> = ({
  setDispatch,
  mylocation,
}) => {
  const [markerRef, marker] = useMarkerRef();
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<any>(null);
  const [address, setAddress] = useState("");
  const [markerPosition, setMarkerPosition] = useState<any>({
    lat: 5.596679535927821,
    lng: -0.15693672001361847,
  });
  const [payload, setPayload] = useState<any>({});

  useEffect(() => {
    if (!marker) {
      console.error("Marker not initialized yet.");
      return;
    }

    const map = marker.getMap();
    if (map) {
      console.log("Map loaded successfully");
    }
  }, [marker]);

  useEffect(() => {
    if (inputRef?.current && window?.google?.maps?.places) {
      const options = {
        types: ["geocode"],
        componentRestrictions: { country: "GH" },
      };

      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );

      autocompleteRef?.current?.addListener("place_changed", () => {
        const place = autocompleteRef.current?.getPlace();
        if (place && place?.geometry) {
          const location = place?.geometry?.location;
          console.log("this is geolocation", location);
          setMarkerPosition(() => ({
            lat: location.lat(),
            lng: location.lng(),
          }));
          const map = marker?.getMap();
          if (map instanceof google.maps.Map) {
            map.setCenter(location);
          }
          setAddress(place?.formatted_address);

          const addressSplit = place?.formatted_address.split(",") || [];
          const updatedPayload = {
            name: addressSplit[0] || "",
            latitude: location?.lat(),
            longitude: location?.lng(),
            city: addressSplit[1] || "",
            region: addressSplit[2] || "",
          };
          setPayload(updatedPayload);
          setDispatch({ type: "SET_LOCATION", payload: updatedPayload });
        }
      });
    } else {
      console.error("Google Maps API not loaded.");
    }
  }, [marker]);

  const handleMapClick = async (event: MapMouseEvent) => {
    const googleEvent = event as unknown as any;
    if (googleEvent.detail.latLng) {
      const latLng = googleEvent.detail.latLng;
      setMarkerPosition(() => ({
        lat: latLng.lat,
        lng: latLng.lng,
      }));
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results?.[0]) {
            setAddress(results[0].formatted_address);
            if (inputRef.current) {
              inputRef.current.value = results[0].formatted_address;
            }

            const addressSplit = results[0].formatted_address.split(",") || [];
            let region;
            let city;
            let name;
            if (addressSplit.length == 2) {
              region = addressSplit[1] || "";
              name = addressSplit[0] || "";
              city = "";
            } else {
              region = addressSplit[2] || "";
              name = addressSplit[0] || "";
              city = addressSplit[1] || "";
            }
            const updatedPayload = {
              name,
              latitude: latLng.lat,
              longitude: latLng.lng,
              city,
              region,
            };
            console.log("updatedPayload", updatedPayload);

            setPayload(updatedPayload);
            setDispatch({ type: "SET_LOCATION", payload: updatedPayload });
          } else {
            console.log("No results found");
          }
        } else {
          console.log("Geocoder failed due to: " + status);
        }
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  };

  useEffect(() => {
    if (!mylocation) return;
    setAddress(`${mylocation?.name} ${mylocation?.city} ${mylocation?.region}`);
    setMarkerPosition(() => ({
      lat: mylocation?.latitude,
      lng: mylocation?.longitude,
    }));
    const map = marker?.getMap();
    if (map instanceof google.maps.Map) {
      map.setCenter({
        lat: mylocation?.latitude,
        lng: mylocation?.longitude,
      });
    }
  }, [mylocation]);

  return (
    <div>
      <input
        className="border border-gray-200 rounded-[10px]"
        ref={inputRef}
        value={address}
        type="text"
        placeholder="Enter a location"
        style={{
          width: "100%",
          padding: "8px",
          marginBottom: "10px",
          boxSizing: "border-box",
        }}
        onChange={handleInputChange}
      />
      <div
        style={{
          height: "300px",
        }}
      >
        <APIProvider apiKey={"AIzaSyDOliBiyCOvoWTL9JJovamsAxsp9Olt_p4"}>
          <Map
            mapId={"e93373b426b7283"}
            defaultZoom={15}
            defaultCenter={markerPosition}
            style={{ borderRadius: "20px" }}
            onClick={handleMapClick}
          >
            <Marker ref={markerRef} position={markerPosition} />
          </Map>
        </APIProvider>
      </div>
    </div>
  );
};

export default LocationMap;
