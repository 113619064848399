import React from "react";
import UserProfile from "../components/shared/userProfile";
import moment from "moment";
import { HiOutlineBell, HiOutlineMagnifyingGlass } from "react-icons/hi2";
import { Badge } from "antd";

const CustomNavbar = () => {
  const today = moment();

  return (
    <>
      <div className="w-full py-3 2xl:px-10 xl:px-10 lg:px-10 md:px-5 px-5 bg-default_bg flex justify-between items-center">
        <div>
          <p className="text-[16px] font-[400] text-text_color">
            {today.format("dddd DD MMM. YYYY")}
          </p>
        </div>

        {/* search */}
        {/* <div className="relative">
          <input
            className="w-[350px] h-[30px] rounded-full border border-[#E5E5FC] focus:outline-none px-3 text-[12px]"
            placeholder="Search"
          />

          <button className="w-10 h-[24px] rounded-[30px] bg-default_blue text-white absolute bottom-[3px] right-1 flex justify-center items-center">
            <HiOutlineMagnifyingGlass className="h-[14px] w-[14px]" />
          </button>
        </div> */}

        {/* <div className='flex items-center gap-5'>
                    <div className='mt-2'>
                        <Badge count={0} showZero overflowCount={5}>
                            <HiOutlineBell className='h-6 w-6 cursor-pointer text-light_blue' />
                        </Badge>
                    </div>

                    <div>
                        <UserProfile />
                    </div>
                </div> */}
      </div>
    </>
  );
};

export default CustomNavbar;
