import family from "../../assets/images/family.png";
import { SlCalender } from "react-icons/sl";
import { GoLocation } from "react-icons/go";
import { MdOutlineMoreVert } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Progress } from "antd";
import moment from "moment";

const EventCard = ({
  image,
  id,
  name,
  status,
  banner,
  location,
  eventDate,
  eventStartDateTime,
  transformedEvent: { total },
}: {
  image?: any;
  name: string;
  banner: string;
  id: string;
  location: any;
  transformedEvent: any;
  status: string;
  eventStartDateTime: string;
  eventDate: string;
}) => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   console.log("transformedEvent", total);
  // }, [total]);

  return (
    <div
      className="event-card bg-white cursor-pointer hover:drop-shadow-none drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] px-[10px] pt-[10px] rounded-[20px] pb-6"
      onClick={() => navigate(`/events/${id}`)}
    >
      <div
        className="event-card-image rounded-[10px]"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
          width: "100%",
          height: "184px",
        }}
      ></div>

      <div className="px-[10px]">
        <p className="font-[600] text-[#201E5A] mt-6 mb-[16px]">
          {name?.toUpperCase() || "UPDATE EVENT NAME"}
        </p>
        <div className="flex-auto hover-content">
          <Progress
            percent={total}
            strokeColor="#6666EA"
            size="small"
            showInfo={false}
          />
          <p className="text-[12px] text-[#9D9CC2] my-4">{total} % Complete</p>
        </div>
        <section className="default-content">
          <div className="flex pb-[8px] items-center gap-2 text-[16px] font-[400] text-[#6666EA]">
            <SlCalender size={"16px"} />
            {moment(eventStartDateTime).format("Do MMM YYYY")} |{" "}
            {moment(eventStartDateTime).format("hh:mm A")}
          </div>
          <div className="flex items-center gap-2 pb-[16px] font-[400] text-[#6F7787]">
            <GoLocation />
            <p>{`${location?.name} ${location?.city} ${location?.region}`}</p>
          </div>
        </section>
        <div className=" flex items-center justify-between">
          <span
            style={{
              background: `${
                status == "DRAFTED"
                  ? "rgba(255, 99, 141, 0.1)"
                  : "rgba(119, 209, 164, 0.1)"
              }`,
              color: `${status == "DRAFTED" ? "#ff638d" : "#77d1a4"}`,
            }}
            className="hover-content px-6 rounded-md font-[400]"
          >{`${status === "DRAFTED" ? "DRAFTED" : "PUBLISHED"}`}</span>
          <p className="default-content">
            <span className="text-[21px] font-[600] text-[#4158F1]">
              $599.00
            </span>{" "}
            <span className="text-[#FF365D] ml-5">23% off</span>
          </p>
          <MdOutlineMoreVert size={"24px"} className="text-[#6666EA]" />
        </div>
      </div>
    </div>
  );
};

export default EventCard;
