import { Button, Input, Space } from "antd";
import { IoPersonCircle } from "react-icons/io5";
import { GoOrganization } from "react-icons/go";
import TextArea from "antd/es/input/TextArea";
import { BsPlus } from "react-icons/bs";
import avata from "../../../../assets/images/avata.png";
import { RiDeleteBin7Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
const OrganisationInformation = ({ image }: { image: string }) => {
  const navigate = useNavigate();
  return (
    <div>
      <section className="flex flex-col p-[24px] w-full ">
        <div className="flex items-center">
          <div className="flex items-center mr-auto gap-4">
            <Button
              className="border-none rounded-[20px] text-[#6666EA] "
              icon={<GoOrganization size={"2em"} />}
            ></Button>
            <p className="text-[30px] font-[600] text-text_color">
              Organization info
            </p>
          </div>
        </div>
        {/* <p className="text-[20px] mt-4 font-[500] text-text_color">Location</p>
        <span className="text-[#6F7787]">
          You can choose the location or pinpoint it on the map
        </span> */}

        <div className="flex w-full justify-between">
          <section className="w-[60%] flex flex-col gap-4">
            <div className="mt-5 flex-auto">
              <span className="text-[#0F0F37]">Organization Name </span>
              <Input
                placeholder="Event Space "
                className="noplaceholder h-[48px] border border-[#E0E0FB] rounded-[10px] "
              />
            </div>

            <div className="">
              <span className="text-[#0F0F37]">Bio</span>
              <TextArea
                rows={4}
                placeholder="Provide essential event details"
                className="noplaceholder  border border-[#E0E0FB]  h-[48px] rounded-[10px]"
                // value={state.eventDescription}
                // onChange={(e) => onEventDescription(e.target.value)}
              />
            </div>
            <div className=" flex-auto">
              <span className="text-[#0F0F37]">Types of Events</span>
              <Input
                placeholder="music, concert"
                className="noplaceholder  border border-[#E0E0FB]  h-[48px] rounded-[10px] "
              />
            </div>
            <div className="flex-auto">
              <span className="text-[#0F0F37]">Website</span>
              <Input
                placeholder="www.eventspace.com"
                className="noplaceholder  border border-[#E0E0FB]  h-[48px] rounded-[10px] "
              />
            </div>
            <div className=" flex-auto">
              <span className="text-[#0F0F37]">Telephone</span>
              <Input
                placeholder="01234567890"
                className="noplaceholder  border border-[#E0E0FB]  h-[48px] rounded-[10px] "
              />
            </div>
            <Button
              className="border-none mt-5 font-[600] flex justify-start rounded-[20px] text-[#6666EA] "
              icon={<BsPlus size={"2em"} />}
            >
              Add social media
            </Button>
            <div className=" ">
              <span className="text-[#0F0F37]">LinkedIn</span>
              <Space.Compact className=" h-[48px] rounded-[8px]">
                <Input
                  style={{ width: "60%" }}
                  className="border border-[#E0E0FB] bg-[#F9F9FF] "
                  defaultValue="http://www.linkedin.com/"
                />
                <Input
                  style={{ width: "50%" }}
                  className="border border-[#E0E0FB]"
                />
              </Space.Compact>
            </div>
            <div className="">
              <span className="text-[#0F0F37]">Twitter</span>
              <Space.Compact className="border  h-[48px] rounded-[8px]">
                <Input
                  style={{ width: "60%" }}
                  defaultValue="http://www.twitter.com/"
                  className="border border-[#E0E0FB] bg-[#F9F9FF] "
                />
                <Input
                  style={{ width: "50%" }}
                  className="border border-[#E0E0FB]"
                />
              </Space.Compact>
            </div>
            <div className="">
              <span className="text-[#0F0F37]">Facebook</span>
              <Space.Compact className="border  h-[48px] rounded-[8px]">
                <Input
                  style={{ width: "60%" }}
                  className="border border-[#E0E0FB] bg-[#F9F9FF] "
                  defaultValue="http://www.facebook.com/"
                />
                <Input
                  style={{ width: "50%" }}
                  className="border border-[#E0E0FB]"
                />
              </Space.Compact>
            </div>
          </section>
          <section>
            <section className=" bg-white mt-5 drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] sticky top-0 rounded-[20px] p-[24px]">
              <div>
                <div className="flex items-center justify-center">
                  {/* <img
                    src={avata}
                    alt=""
                    className="rounded-full w-[133px] h-[133px]"
                  /> */}
                  {!image ? (
                    <img
                      src={avata}
                      alt="avatar"
                      className="rounded-full w-[133px] h-[133px]"
                    />
                  ) : (
                    <div
                      className="rounded-[10px]"
                      style={{
                        width: "133px",
                        height: "133px",
                        backgroundImage: `url(${image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                  )}
                </div>
                {/* <p className="text-[#6E6C8E] mt-2  text-center">
                  Add your organisation Logo
                </p>
                <p className="text-[#6E6C8E] mt-2 text-[12px]  text-center">
                  The recommended file formats are :<br /> jpg, jpeg, png.
                </p> */}
              </div>
              {/* <div className="flex items-center  justify-center mt-4">
                <Button
                  className="border-none rounded-[20px] text-[#DE3B40] "
                  icon={<RiDeleteBin7Line />}
                >
                  Remove
                </Button>
                <button className="  text-[#5353C2] rounded-[20px] border border-[#E0E0FB] py-[4px] px-[16px] ">
                  Upload
                </button>
              </div> */}
            </section>
          </section>
        </div>
      </section>
      <div className="flex w-full mt-5 pb-4 pr-4 justify-end">
        <Button
          className="custom-button rounded-[40px] py-[8px] px-[50px] text-white bg-[#6666EA] border-1 border-solid border-[#6666EA]"
          onClick={() => navigate("/dashboard")}
        >
          continue
        </Button>
      </div>
    </div>
  );
};

export default OrganisationInformation;
