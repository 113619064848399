import React from "react";
import Skeleton from "react-loading-skeleton";

const EventCardSkeleton = ({ cards }: { cards: any }) => {
  return (
    <div className="grid grid-cols-3">
      {Array(cards)
        .fill(0)
        .map((item, i) => (
          <div className="card-skeleton" key={i}>
            <div className="left-col">
              <Skeleton height={184} style={{ borderRadius: "10px" }} />
            </div>
            <div className="right-col">
              <Skeleton style={{ marginBottom: "0.6rem", width: "90%" }} />
              <Skeleton style={{ marginBottom: "0.6rem", width: "60%" }} />
              <Skeleton style={{ marginBottom: "0.6rem", width: "30%" }} />

              <Skeleton style={{ marginBottom: "0.6rem", width: "100%" }} />
            </div>
          </div>
        ))}
    </div>
  );
};

export default EventCardSkeleton;
