import { StorageBox } from "../../../helpers/stroage";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  return (
    <div className=" relative flex justify-center items-center w-full h-full">
      <section className="bg-white rounded-lg drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] p-6 max-w-sm text-center">
        <h1 className="text-lg font-semibold mb-4 text-[#252363]">
          Are you sure you want to logout?
        </h1>
        <div className="flex p-2 gap-4">
          <button
            className="flex-1 bg-red-500 text-white rounded-md py-2 hover:bg-red-600 transition-colors"
            onClick={() => {
              StorageBox.clearStorage();
              navigate("/signin");
            }}
          >
            Yes
          </button>
          <button
            className="flex-1 bg-gray-300 text-gray-700 rounded-md py-2 hover:bg-gray-400 transition-colors"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Cancel
          </button>
        </div>
      </section>
    </div>
  );
};

export default Logout;
