import { useEffect } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useGeneralContext } from "../context/generalContext";
import logoImg from "../assets/images/logos.svg";
import {
  HiOutlineBars3,
  HiOutlineHome,
  HiOutlineChartBarSquare,
  HiOutlineCog,
} from "react-icons/hi2";
import {
  MdOutlineVideoLibrary,
  MdOutlineAdminPanelSettings,
} from "react-icons/md";
import { AiOutlineMessage } from "react-icons/ai";
import { BsPersonGear } from "react-icons/bs";
interface sideBarProps {
  dashActive?: any;
  eventActive?: any;
  perfActive?: any;
  notifyActive?: any;
  adminActive?: any;
  settingsActive?: any;
}

const CustomSideBar = ({
  dashActive,
  eventActive,
  perfActive,
  notifyActive,
  adminActive,
  settingsActive,
}: sideBarProps) => {
  const { isCollapsed, setIsCollapsed } = useGeneralContext();

  // Function to handle window resize
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsCollapsed(true);
    }
  };

  // Add event listener for window resize
  useEffect(() => {
    handleResize(); // Set initial state based on current window size
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <Sidebar collapsed={isCollapsed}>
      <Menu className="h-screen bg-white">
        {/* profile */}
        <div className="py-5 px-[28px] flex gap-5 items-center">
          <div>
            <HiOutlineBars3
              className="h-6 w-6 text-default_blue cursor-pointer"
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          </div>

          {!isCollapsed && (
            <div>
              <img src={logoImg} alt="" />
            </div>
          )}
        </div>

        <div>
          <MenuItem
            icon={<HiOutlineHome className="w-5 h-5 text-default_blue" />}
            component={<Link to="/dashboard" />}
            className={`text-[16px] font-[400] text-[#757494] ${dashActive}`}
          >
            <p className="text-sm ">Dashboard</p>
          </MenuItem>
        </div>

        <div className="mt-2">
          <MenuItem
            icon={<MdOutlineVideoLibrary className="w-5 h-5" />}
            component={<Link to="/events" />}
            className={`text-[16px] font-[400] text-default_blue  ${eventActive}`}
          >
            <p className="text-sm text-[#757494]">Events</p>
          </MenuItem>
        </div>

        {/* <div className="mt-2">
          <SubMenu
            className={`text-[16px] font-[400] text-default_blue ${perfActive}`}
            label={<p className="text-sm text-[#757494]">Performance</p>}
            icon={<HiOutlineChartBarSquare className="w-5 h-5" />}
          >
            <MenuItem>
              <p className="text-sm text-[#757494]">Overall</p>
            </MenuItem>
          </SubMenu>
        </div> */}
        <div className="mt-2">
          <MenuItem
            icon={<HiOutlineChartBarSquare className="w-5 h-5" />}
            component={<Link to="/report" />}
            className={`text-[16px] font-[400] text-default_blue ${notifyActive}`}
          >
            <p className="text-sm text-[#757494]">Reports</p>
          </MenuItem>
        </div>
        <div className="mt-2">
          <MenuItem
            icon={<BsPersonGear className="w-5 h-5" />}
            component={<Link to="/profile" />}
            className={`text-[16px] font-[400] text-default_blue  ${eventActive}`}
          >
            <p className="text-sm text-[#757494]">Profile</p>
          </MenuItem>
        </div>
        {/* <div className="mt-2">
          <MenuItem
            icon={<AiOutlineMessage className="w-5 h-5" />}
            component={<Link to="/notifications" />}
            className={`text-[16px] font-[400] text-default_blue ${notifyActive}`}
          >
            <p className="text-sm text-[#757494]">Notifiations</p>
          </MenuItem>
        </div>

        <div className="px-[28px] my-[10px]">
          <hr className="border-[#CCCCF8]" />
        </div>

        <div className="mt-2">
          <SubMenu
            className={`text-[16px] font-[400] text-default_blue ${adminActive}`}
            label={<p className="text-sm text-[#757494]">Administration</p>}
            icon={<MdOutlineAdminPanelSettings className="w-5 h-5" />}
          >
            <MenuItem>
              <p className="text-sm text-[#757494]">Roles & Permissions</p>
            </MenuItem>
          </SubMenu>
        </div>

        <div className="px-[28px] my-[10px]">
          <hr className="border-[#CCCCF8]" />
        </div>

        <div className="mt-2">
          <MenuItem
            icon={<HiOutlineCog className="w-5 h-5" />}
            component={<Link to="/settings" />}
            className={`text-[16px] font-[400] text-default_blue ${settingsActive}`}
          >
            <p className="text-sm text-[#757494]">Settings</p>
          </MenuItem>
        </div> */}
      </Menu>
    </Sidebar>
  );
};

export default CustomSideBar;
