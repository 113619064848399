import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import MyEditor from "./Editor";
import Quill from "quill";
import { Input, Spin } from "antd";
import { MdOutlinePolicy } from "react-icons/md";
import RangeStatic from "quill";
import BaseService from "../../../../helpers/baseServices";
import { ToastService } from "../../../../components/shared/ToastNotifications/ToastNotifications";
const Policy = forwardRef(
  (props: { id: any; setPolicy: any; myStatus: any }, ref) => {
    const quillRef = useRef<Quill | null>(null);
    const [lastChange, setLastChange] = useState<any>(null);
    const [policyData, setPolicyData] = useState<any>();
    const [range, setRange] = useState<RangeStatic | null>(null);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [isUpdateLoading, setUpdateLoading] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const GetEventData = async () => {
      setLoading(true);
      try {
        const results = await BaseService.get_api(
          `/robocentre/event/event-single/${props.id}`
        );
        const { payload } = results.data;
        const { policy } = payload;
        if (quillRef.current) {
          quillRef.current.clipboard.dangerouslyPasteHTML(policy);
        }
      } catch (error) {
        console.log("This is the error", error);
      } finally {
        setLoading(false);
      }
    };

    const updatePolicy = async () => {
      // setUpdateLoading(true);
      try {
        const payload = {
          _id: props.id,
          policy: policyData,
          status: props.myStatus,
        };
        const results = await BaseService.put_api(
          "/robocentre/event/event",
          payload
        );
        console.log("Sending payload:", payload);
        ToastService.ShowSuccess("Updated Successfully !");

        setRefresh((prev) => !prev);
      } catch (error: any) {
        ToastService.ShowError(error.message);

        console.log("Error", error.message);
      } finally {
        // setUpdateLoading(false);
      }
    };

    // useEffect(() => {
    //   if (quillRef.current) {
    //     quillRef.current.clipboard.dangerouslyPasteHTML(policyData);
    //   }
    // }, [quillRef.current]);
    useImperativeHandle(ref, () => ({
      updatePolicy,
    }));
    const onEventDescription = (description: string) => {
      setPolicyData(description);
    };

    useEffect(() => {
      GetEventData();
    }, [refresh]);
    return (
      <div className="my-10 px-6 pb-4 rounded-[10px] bg-white">
        <Spin spinning={isLoading}>
          <div className="flex  items-center gap-4 py-6">
            <MdOutlinePolicy size={"2.5em"} className="text-[#6D31ED]" />
            <h1 className="text-[30px] text-[#0F0F37] font-[600] ">Policy</h1>
          </div>

          <MyEditor
            height="50vh"
            readOnly={readOnly}
            onTextChange={(delta, oldDelta, source) => {
              setLastChange({ delta, oldDelta, source });
              const content = quillRef.current?.root.innerHTML || "";
              onEventDescription(content);
            }}
            onSelectionChange={(range, oldRange, source) => {
              setRange(range);
            }}
            ref={quillRef}
          />
        </Spin>
      </div>
    );
  }
);

export default Policy;
