import React from 'react'
import CustomNavbar from './customNavbar'
import CustomSideBar from './customSidebar'


interface layoutProps {
    children: any
    dashActive?: any
    eventActive?: any
    perfActive?: any
    notifyActive?: any
    adminActive?: any
    settingsActive?: any
}


const PageLayout = ({ dashActive, eventActive, perfActive, notifyActive, adminActive, settingsActive, children }: layoutProps) => {
    return (
        <div className='h-screen overflow-hidden'>

            <div className="h-full flex">
                {/* sidebar */}
                <div className='h-full bg-white'>
                    <CustomSideBar dashActive={dashActive} eventActive={eventActive} perfActive={perfActive} notifyActive={notifyActive} adminActive={adminActive} settingsActive={settingsActive} />
                </div>

                <div className="h-full w-full pb-10">
                    <CustomNavbar />
                    {children}
                </div>
            </div>
        </div>
    )
}

export default PageLayout
