import { Button, Input } from "antd";
import { IoPersonCircle } from "react-icons/io5";
const ChangePassword = () => {
  return (
    <div>
      <section className="flex-col p-[24px] w-[80%] mr-auto ml-auto">
        <div className="flex items-center">
          <div className="flex items-center mr-auto gap-4">
            <p className="text-[30px] font-[600] text-text_color">
              Change Password
            </p>
          </div>
        </div>
        <div className="mt-5 flex-auto">
          <span className="text-[#0F0F37]">First name </span>
          <Input
            placeholder="John"
            className="noplaceholder h-[48px] border border-[#E0E0FB] rounded-[10px] "
          />
        </div>

        <div className="mt-5 flex-auto">
          <span className="text-[#0F0F37]">Last name</span>
          <Input
            placeholder="Doe"
            className="noplaceholder  border border-[#E0E0FB]  h-[48px] rounded-[10px] "
          />
        </div>
        <div className="mt-5 flex-auto">
          <span className="text-[#0F0F37]">Password</span>
          <Input
            placeholder="Doe"
            className="noplaceholder  border border-[#E0E0FB]  h-[48px] rounded-[10px] "
          />
        </div>

        <div className="flex w-full mt-5 ">
          <Button
            className="custom-button rounded-[40px] w-full py-[10px] px-[50px] text-white bg-[#6666EA] border-1 border-solid border-[#6666EA]"
            //   onClick={() => navigate("/events")}
          >
            save
          </Button>
        </div>
      </section>
    </div>
  );
};

export default ChangePassword;
