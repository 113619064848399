import {
  Checkbox,
  CheckboxProps,
  DatePicker,
  Input,
  Spin,
  TimePicker,
} from "antd";
import { GoLocation } from "react-icons/go";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import { MAPS_KEY } from "../../../../helpers/constants";
import BaseService from "../../../../helpers/baseServices";
import { customStyles } from "./customStyles";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import type { DatePickerProps, TimePickerProps } from "antd";
import { useParams } from "react-router-dom";
import LocationMap from "./LocationMap";
import { ToastService } from "../../../../components/shared/ToastNotifications/ToastNotifications";
dayjs.extend(customParseFormat);

interface Location {
  name: string;
  latitude: number;
  longitude: number;
  city: string;
  region: string;
}
interface InitUI {
  startDate: string;
  endDate: string;
  image: string[];
  status: string;
  location: Location;
}

type Action =
  | { type: "SET_EVENT_ALL"; payload: Partial<InitUI> }
  | { type: "SET_START_DATE"; payload: string }
  | { type: "SET_END_DATE"; payload: string }
  | { type: "SET_LOCATION"; payload: Location }
  | { type: "SET_IMAGE"; payload: string[] }
  | { type: "SET_STATUS"; payload: string };

const reducer = (state: InitUI, action: Action): InitUI => {
  switch (action.type) {
    case "SET_START_DATE":
      return { ...state, startDate: action.payload };
    case "SET_END_DATE":
      return { ...state, endDate: action.payload };
    case "SET_IMAGE":
      return { ...state, image: action.payload };
    case "SET_STATUS":
      return { ...state, status: action.payload };
    case "SET_LOCATION":
      return { ...state, location: action.payload };
    case "SET_EVENT_ALL":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const initialState: InitUI = {
  startDate: "N/A",
  endDate: "N/A",
  image: [],
  status: "DRAFTED",
  location: {
    name: "Burma Camp",
    latitude: 5.596679535927821,
    longitude: -0.15693672001361847,
    city: ",Accra",
    region: "Ghana",
  },
};

const LocationNTime = forwardRef((props: { id: string | undefined }, ref) => {
  const [isCheck, setChecked] = useState<boolean | undefined>(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const { id } = useParams();
  const [TimeFirst, setTimeFirst] = useState<any>();
  const [TimeSecond, setTimeSecond] = useState<any>();
  const [DateFirst, setDateFirst] = useState<any>();
  const [DateSecond, setDateSecond] = useState<any>();
  const [state, dispatch] = useReducer(reducer, initialState);

  const GetEventData = async () => {
    setLoading(true);
    try {
      const results = await BaseService.get_api(
        `/robocentre/event/event-single/${props.id}`
      );
      const { payload } = results.data;
      const { status, eventStartDateTime, eventEndDateTime, location } =
        payload;

      setDateFirst(dayjs(eventStartDateTime));
      setTimeFirst(dayjs(eventStartDateTime));
      setDateSecond(dayjs(eventEndDateTime));
      setTimeSecond(dayjs(eventEndDateTime));

      dispatch({
        type: "SET_EVENT_ALL",
        payload: {
          status: status,
          startDate: eventStartDateTime,
          endDate: eventEndDateTime,
          location: location,
        },
      });
      // console.log(
      //   "this is the payload",
      //   status,
      //   eventStartDateTime,
      //   eventEndDateTime
      // );
    } catch (error) {
      console.log("This is the error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetEventData();
  }, []);

  const updateEventsLocation = async () => {
    setUpdateLoading(true);
    try {
      const eventStartDateTime = DateFirst.set("hour", TimeFirst.hour())
        .set("minute", TimeFirst.minute())
        .toISOString();
      const eventEndDateTime = DateSecond.set("hour", TimeSecond.hour())
        .set("minute", TimeSecond.minute())
        .toISOString();

      if (new Date(eventStartDateTime) >= new Date(eventEndDateTime)) {
        throw new Error("Event start time must be earlier than the end time.");
      }

      const payload = {
        _id: id,
        status: state.status,
        eventStartDateTime,
        eventEndDateTime,
        location: state.location,
      };
      const results = await BaseService.put_api(
        "/robocentre/event/event",
        payload
      );
      ToastService.ShowSuccess("Updated Successfully !");
      console.log("Sending payload:", payload);
    } catch (error: any) {
      ToastService.ShowError(error.message);
      console.log("Error", error.message);
    } finally {
      setUpdateLoading(false);
    }
  };
  useImperativeHandle(ref, () => ({
    updateEventsLocation,
  }));

  const onChangeCheck: CheckboxProps["onChange"] = (e) => {
    setChecked(e.target.checked);
  };
  const onChangeFirstTime: TimePickerProps["onChange"] = (
    time,
    timeString: any
  ) => {
    setTimeFirst(dayjs(timeString, "HH:mm"));
  };
  const onChangeSecondTime: TimePickerProps["onChange"] = (
    time,
    timeString: any
  ) => {
    setTimeSecond(dayjs(timeString, "HH:mm"));
  };
  const onChangeDateFirst: DatePickerProps["onChange"] = (
    date,
    dateString: any
  ) => {
    setDateFirst(dayjs(dateString));
  };
  const onChangeDateSecond: DatePickerProps["onChange"] = (
    date,
    dateString: any
  ) => {
    setDateSecond(dayjs(dateString));
  };

  // useEffect(() => {
  //   console.log("state", state);
  // }, [TimeFirst, TimeSecond, DateFirst, DateSecond, dispatch]);

  return (
    <div className="drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] mt-10 bg-white  mb-10 rounded-[10px] pb-10 px-6">
      <Spin spinning={isLoading}>
        <div className="flex  items-center gap-4 pt-6">
          <GoLocation className="w-[24px] text-[#6D31ED] h-[24px]" />
          <h1 className="text-[20px] font-[600] text-[#171A1F]">
            Location and time
          </h1>
        </div>
        <p className="text-[21px] text-[#171A1F] font-[600] my-4">Location</p>

        <p className="text-[14px] font-[400] mb-2">
          You can choose the location or pinpoint it on the map{" "}
        </p>
        {/* <Checkbox
          onChange={onChangeCheck}
          checked={isCheck}
          className="custom-checkbox"
        >
          Location Address on map
        </Checkbox> */}
        <section className="flex gap-4 mt-[16px] ">
          <section className="flex-auto">
            {isCheck ? (
              <LocationMap setDispatch={dispatch} mylocation={state.location} />
            ) : (
              <div>
                <div>
                  <span className="text-[#0F0F37]">Address</span>
                  <Input
                    placeholder="Enter your address "
                    className="noplaceholder h-[48px] rounded-[10px] "
                  />
                </div>
                <section className="flex gap-4 w-full">
                  <div className="mt-5 flex-auto">
                    <span className="text-[#0F0F37]">City</span>
                    <Input
                      placeholder="Enter your city "
                      className="noplaceholder h-[48px] rounded-[10px] "
                    />
                  </div>

                  <div className="mt-5 flex-auto">
                    <span className="text-[#0F0F37]">State / province</span>
                    <Input
                      placeholder="Enter your State / province"
                      className="noplaceholder h-[48px] rounded-[10px] "
                    />
                  </div>
                </section>
                <div className="mt-5">
                  <span className="text-[#0F0F37]">Country</span>
                  <Input
                    placeholder="Enter your country "
                    className="noplaceholder h-[48px] rounded-[10px] "
                  />
                </div>
              </div>
            )}
          </section>
          {/* {isCheck ? <LocationMap /> : <></>} */}
        </section>
        <section className="my-10 flex flex-col gap-10">
          <section className="flex gap-8 ">
            <div className="flex flex-col w-full">
              <p className="text-[#0F0F37]">Event Start Date</p>
              <DatePicker
                className="w-full h-[48px] rounded-[10px]"
                value={DateFirst}
                onChange={onChangeDateFirst}
              />
            </div>
            <div className="flex flex-col w-full">
              <p className="text-[#0F0F37]">Event Start Time</p>
              <TimePicker
                className="w-full h-[48px] rounded-[10px]"
                value={TimeFirst}
                onChange={onChangeFirstTime}
                format="HH:mm"
              />
            </div>
          </section>
          <section className="flex gap-8">
            <div className="flex flex-col w-full">
              <p className="text-[#0F0F37]">Event End Date</p>
              <DatePicker
                className="w-full h-[48px] rounded-[10px]"
                value={DateSecond}
                onChange={onChangeDateSecond}
              />
            </div>
            <div className="flex flex-col w-full">
              <p className="text-[#0F0F37]">Event End Time</p>
              <TimePicker
                className="w-full h-[48px] rounded-[10px]"
                value={TimeSecond}
                onChange={onChangeSecondTime}
                format="HH:mm"
              />
            </div>
          </section>
        </section>
      </Spin>
    </div>
  );
});
export default LocationNTime;
