import { useEffect, useReducer, useState } from "react";
import { Checkbox, Progress } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PersonalInformation from "./Tabs/PersonalInformation";
import OrganisationInformation from "./Tabs/OrganisationInformation";
import BaseService from "../../../helpers/baseServices";

interface AuthUI {
  firstName: string;
  lastName: string;
  Email: string;
  telephone: string;
  gender?: string;
  image: string;
}

type Action = { type: "SET_INIT"; payload: AuthUI };

const reducer = (state: AuthUI, action: Action) => {
  switch (action.type) {
    case "SET_INIT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const initState = {
  firstName: "",
  lastName: "",
  Email: "",
  telephone: "",
  image: "",
};

const ActivateAccount = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [state, dispatch] = useReducer(reducer, initState);
  const [loading, setLoading] = useState<boolean>(false);
  const [RefreshData, setRefreshData] = useState<boolean>(false);

  const getPersonalInformation = async () => {
    setLoading(true);
    try {
      const results = await BaseService.get_api(`/robocentre/user/details`);
      const { payload } = results.data;
      const {
        firstName,
        lastName,
        phone: telephone,
        email: Email,
        gender,
        image,
      } = payload;
      dispatch({
        type: "SET_INIT",
        payload: { firstName, lastName, telephone, Email, gender, image },
      });
      console.log("payload", payload);
    } catch (error: any) {
      console.log("Message sending error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const getRefresh = (refreshValue: boolean) => {
    setRefreshData(refreshValue);
  };

  const tabNames = ["General info", "Location and time"];
  const Tabs = [
    <PersonalInformation
      onRefreshHandler={getRefresh}
      Mainstate={state}
      getloading={loading}
      setTab={setActiveTab}
    />,
    <OrganisationInformation image={state.image} />,
  ];

  useEffect(() => {
    getPersonalInformation();
  }, [RefreshData]);

  return (
    <div className="w-full bg-default_bg h-screen overflow-y-auto p-5 font-baloo">
      <p className="text-[42px] z-[200] mt-[50px] font-[600] text-text_color text-center">
        Activate your Account
      </p>

      <section className="w-full flex items-center justify-center gap-10">
        <div className="mt-[30px] w-[70%] flex gap-10">
          <div className="flex sticky top-0 h-[300px] drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] w-[300px] flex-col">
            <section className="py-4 bg-white rounded-[10px]">
              {tabNames.map((d, i) => (
                <div
                  key={i}
                  className={`${
                    activeTab === i
                      ? "bg-[#F9F9FF] font-[600] border-l-4 border-l-[#6666EA]"
                      : "text-[#252363]"
                  } text-[#252363] bg-white cursor-pointer`}
                  onClick={() => setActiveTab(i)}
                >
                  <div className="gap-4 flex px-6 py-4">
                    <Checkbox
                      checked={activeTab === i}
                      className="activate-checkbox"
                    />
                    <p>{d}</p>
                  </div>
                </div>
              ))}
            </section>
            <Progress
              percent={50}
              strokeColor="#63C477"
              size="small"
              className="mt-[40px]"
              showInfo={true}
            />
          </div>
          <div className="flex-auto rounded-[10px] bg-white drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)]">
            {Tabs[activeTab]}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ActivateAccount;
