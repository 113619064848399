import { useEffect, useReducer, useState } from "react";
import { Button, Input, Modal, Spin } from "antd";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import { BsPersonGear } from "react-icons/bs";
import BaseService from "../../../helpers/baseServices";
interface ModalProps {
  open: boolean;
  roleID: string;
  setOpenSMS: (open: boolean) => void;
  handleReFetch?: any;
}
interface initUI {
  name: string;
  description: string;
}

type Action =
  | { type: "SET_NAME"; payload: string }
  | { type: "SET_DESCRIPTION"; payload: string }
  | { type: "INIT_SETUP"; payload: any };

const reducer = (state: initUI, action: Action) => {
  switch (action.type) {
    case "SET_NAME":
      return { ...state, name: action.payload };
    case "SET_DESCRIPTION":
      return { ...state, description: action.payload };
    case "INIT_SETUP":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const initialState = {
  name: "",
  description: "",
};
const EditFunction = ({
  open,
  roleID,
  setOpenSMS,
  handleReFetch,
}: ModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const handleCancel = () => {
    setOpenSMS(false);
  };

  const handleOk = async () => {
    setLoading(true);
    try {
      const payload = {
        _id: roleID,
        name: state.name,
        description: state.description,
      };
      console.log("Sending payload:", payload);
      const results = await BaseService.put_api(
        "/robocentre/event/job-function",
        payload
      );
      handleReFetch();
      setOpenSMS(false);
      console.log("API results:", results);
    } catch (error: any) {
      console.log("Message sending  error:", error.message);
      setOpenSMS(true);
    } finally {
      setLoading(false);
    }
  };

  const getSingleFunction = async () => {
    setLoading(true);
    try {
      const results = await BaseService.get_api(
        `/robocentre/event/job-function?filter={"_id": {"$$_id": ${JSON.stringify(
          roleID
        )}}}`
      );
      const { payload } = results.data;
      const { name, description } = payload[0];
      console.log("API payload:", name, description);
      dispatch({ type: "INIT_SETUP", payload: { name, description } });
    } catch (error: any) {
      console.log("Message sending  error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSingleFunction();
  }, [roleID]);

  const handleTitle = (value: string) => {
    dispatch({ type: "SET_NAME", payload: value });
  };
  const handleTextArea = (value: string) => {
    dispatch({ type: "SET_DESCRIPTION", payload: value });
  };

  return (
    <>
      <Modal
        width={"35%"}
        open={open}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={handleCancel}
        okText="Edit Type"
        okButtonProps={{
          style: {
            backgroundColor: "#6666EA",
            color: "#FFFFFF",
            boxShadow: "none",
          },
        }}
        cancelButtonProps={{
          style: { boxShadow: "none" },
        }}
        styles={{ body: { minHeight: "30vh" } }}
      >
        <Spin
          spinning={loading}
          //   indicator={
          //     <div className="flex justify-center items-center  ">
          //       <LoadingDataComponent />
          //     </div>
          //   }
        >
          <div>
            <div className="flex gap-6 items-center">
              <BsPersonGear
                size={"2em"}
                className="text-[600] text-[#0F0F37]"
              />

              <p className="text-[30px] font-[600] text-[#0F0F37]">
                Add Attendee Function
              </p>
            </div>

            {/* <div className="w-full border-[1px] border-gray-300 "></div> */}
            <section className="my-6 flex flex-col gap-6">
              <div className="">
                <span className="text-[#0F0F37]">Title</span>
                <Input
                  placeholder="Enter your role"
                  className="noplaceholder h-[48px] rounded-[10px]"
                  value={state.name}
                  onChange={(e) => handleTitle(e.target.value)}
                />
              </div>
              <div className="">
                <span className="text-[#0F0F37]">Description</span>
                <TextArea
                  rows={4}
                  placeholder="describe your role"
                  className="noplaceholder h-[48px] rounded-[10px]"
                  value={state.description}
                  onChange={(e) => handleTextArea(e.target.value)}
                />
              </div>
            </section>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default EditFunction;
