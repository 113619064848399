import PageLayout from "../../layouts/pageLayout";
import ReportCard from "./ReportCards";

const Report = () => {
  return (
    <>
      <PageLayout dashActive="active">
        <div className="w-full bg-default_bg h-full overflow-y-auto p-5">
          <p className="text-[32px] font-[700] text-text_color">Report</p>
          <ReportCard />
        </div>
      </PageLayout>
    </>
  );
};

export default Report;
