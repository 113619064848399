import { useEffect, useState, useCallback } from "react";
import { Input, Select, Spin } from "antd";
import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { CiSearch } from "react-icons/ci";
import PageLayout from "../../layouts/pageLayout";
import EventCard from "./EventCard";
import BaseService from "../../helpers/baseServices";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import EventCardSkeleton from "../../components/skeleton/EventCardSkeleton";

const trackData = [
  "about",
  "banner",
  "description",
  "eventEndDateTime",
  "eventStartDateTime",
  "image",
  "location",
  "name",
  "policy",
  "status",
];
interface TransformedEvent {
  total: number;
  [key: string]: any;
}

const transformPayload = (
  payload: any[],
  trackData: string[]
): TransformedEvent[] => {
  return payload.map((event) => {
    let transformedEvent: TransformedEvent = { total: 0 };
    let sum = 0;

    trackData.forEach((key) => {
      let value = 0;

      if (
        key === "about" ||
        key === "banner" ||
        key === "eventEndDateTime" ||
        key === "eventStartDateTime" ||
        key === "name" ||
        key === "status"
      ) {
        value = typeof event[key] === "string" && event[key].trim() ? 10 : 0;
      } else if (key === "description" || key === "policy") {
        value =
          typeof event[key] === "string" &&
          event[key].trim() &&
          event[key] !== "<p><br></p>"
            ? 10
            : 0;
      } else if (key === "location") {
        value =
          typeof event[key] === "object" &&
          event[key] &&
          Object.keys(event[key]).length > 0
            ? 10
            : 0;
      } else if (key === "image") {
        value = Array.isArray(event[key]) && event[key].length > 0 ? 10 : 0;
      }

      if (key === "status") {
        transformedEvent[key] = {
          value,
          actualStatus: event[key] || "",
        };
      } else {
        transformedEvent[key] = value;
      }

      sum += value;
    });

    transformedEvent.total = sum;
    return transformedEvent;
  });
};

const EventsPage = () => {
  const [loading, setLoading] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [transformedEvents, setTransformedEvents] = useState<any>([]);
  const getAllEvents = useCallback(async () => {
    try {
      const results = await BaseService.get_api("/robocentre/event/event");
      const { payload } = results.data;
      setAllEvents(payload);
      const transformedPayload = transformPayload(payload, trackData);
      setTransformedEvents(transformedPayload);
      console.log("results", results);

      setLoading(true);
    } catch (error) {
      console.error("Error fetching events:", error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getAllEvents();
  }, [getAllEvents]);

  return (
    <PageLayout eventActive="active">
      <div className="w-full bg-default_bg h-full overflow-y-auto p-5 font-baloo">
        <section className="">
          <p className="text-[32px] font-[700] text-text_color">Events</p>

          <div className="flex justify-between  py-6 hover:drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] drop-shadow-none items-center bg-white rounded-[10px]">
            <div className="ml-4 w-full flex items-center gap-4">
              <div className="w-[30%]">
                <Input
                  size="large"
                  placeholder="Search"
                  className="noplaceholder rounded-[20px] border-[1px] border-[#9494f5] text-[#9494f5]"
                  suffix={<CiSearch />}
                />
              </div>
              <div className="h-[40px] flex-auto">
                <Select
                  defaultValue="Weekdays"
                  allowClear
                  className="roundedSelect w-full h-full"
                  options={[{ value: "Filter", label: "Filter" }]}
                />
              </div>
              <div className="h-[40px] flex-auto">
                <Select
                  defaultValue="Event type"
                  allowClear
                  className="roundedSelect w-full h-full"
                  options={[{ value: "Filter", label: "Filter" }]}
                />
              </div>
              <div className="h-[40px] flex-auto">
                <Select
                  defaultValue="Any category"
                  allowClear
                  className="roundedSelect w-full h-full"
                  options={[{ value: "Filter", label: "Filter" }]}
                />
              </div>
              <div className="flex gap-4 ml-auto mr-4 items-center">
                <Link
                  className="text-white flex gap-3 items-center justify-center py-2 px-3 bg-default_blue rounded-full"
                  to="/events/create_events"
                >
                  <FiPlus size={"1.6em"} /> <span>Create Event</span>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <div className="mt-[30px]">
          {/* <Spin spinning={loading}> */}
          {!loading ? (
            <EventCardSkeleton cards={5} />
          ) : (
            <section className="grid grid-cols-3 gap-4">
              {allEvents.map((event: any, i) => (
                <EventCard
                  key={event._id}
                  id={event._id}
                  transformedEvent={transformedEvents[i]}
                  name={event.name}
                  image={event.image}
                  banner={event.banner}
                  location={event.location}
                  status={event.status}
                  eventDate={event.eventDate}
                  eventStartDateTime={event?.eventStartDateTime}
                />
              ))}
            </section>
          )}
          {/* </Spin> */}
        </div>
      </div>
    </PageLayout>
  );
};

export default EventsPage;
