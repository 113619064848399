// src/contexts/AppContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { StorageBox } from '../helpers/stroage';


// Create the context with a default value
const GeneralContext = createContext<any>(null);

// Create a provider component
export const GeneralContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = useState<string>('');
    const user_data = StorageBox.retrieveUserData()

    return (
        <GeneralContext.Provider value={{ isCollapsed, setIsCollapsed, user_data }}>
            {children}
        </GeneralContext.Provider>
    );
};

// Create a custom hook for easy access to the context
export const useGeneralContext = () => {
    const context = useContext(GeneralContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
