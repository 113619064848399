import React, { useEffect, useState } from "react";
import { IoIosArrowDropright, IoMdMore } from "react-icons/io";
import { Dropdown, Input, MenuProps, Select, Spin } from "antd";
import { customer_list } from "../../../../helpers/data";
import { CiEdit, CiSearch } from "react-icons/ci";
import DataTable from "react-data-table-component";
import useTableSyle from "./useTableStyle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { BsTicketPerforated } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import { BiMessageRoundedDots } from "react-icons/bi";
import BaseService from "../../../../helpers/baseServices";
import moment from "moment";
const Attendee = () => {
  const [customStyles] = useTableSyle();
  const [activeTab, setActiveTab] = useState(0);
  const { id } = useParams();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [AllAllAttendee, setAllAllAttendee] = useState<any>([]);
  const [total, setTotal] = useState<number>();

  const navigate = useNavigate();

  const GetAllAttendee = async () => {
    setLoading(true);
    try {
      const results = await BaseService.get_api(
        `/robocentre/event/user-registeration-users/${id}`
      );
      console.log("single event Attendee : ", results.data.payload);
      setAllAllAttendee(results.data.payload);
      setTotal(results.data.total);
    } catch (error: any) {
      console.log("error message is", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllAttendee();
  }, [id]);

  const handleMenuClick = () => {};
  const columns: any = [
    {
      name: "Attendee",
      cell: (row: any) => {
        const fName = row?.firstName || "";
        const MName = row?.middleName || "";
        const LName = row?.lastName || "";
        return <p>{`${fName} ${MName} ${LName}`}</p>;
      },
    },
    {
      name: "Email ",
      cell: (row: any) => row?.email,
      width: "25%",
    },
    {
      name: "Company Name",
      cell: (row: any) => row?.companyName,
    },

    {
      name: <p className="w-full  ml-5">Type</p>,
      selector: (row: { current_status: any }) => {
        const isStatus =
          row?.current_status != "unverified" ? "verified" : "unverified";
        const backgroundColor =
          isStatus === "verified"
            ? "rgba(119, 209, 164, 0.1)"
            : "rgba(255, 99, 141, 0.1)";
        const textColor = isStatus === "verified" ? "#77D1A4" : "#FF638D";

        return (
          <div
            className=" flex justify-center items-center  w-full  rounded-[30px] px-[30px] h-[30px]   pb-[3px]"
            style={{ backgroundColor, color: textColor }}
          >
            {isStatus}
          </div>
        );
      },
    },
    {
      name: "Date",
      cell: (row: any) =>
        moment(row?.createdAt).format("Do MMM YYYY - hh:mm A"),
    },
    // {
    //   name: "",
    //   cell: (row: any) => {
    //     const menuItems: MenuProps["items"] = [
    //       {
    //         key: "1",
    //         label: (
    //           <span className="text-default_ash text-sm">View Details</span>
    //         ),
    //         icon: <CiEdit size={"1.3em"} />,
    //         // onClick: (e) => handleMenuClick(e, row.trip_id, row._id),
    //       },
    //       {
    //         key: "2",
    //         label: (
    //           <span className="text-default_ash text-sm">Send Message</span>
    //         ),
    //         icon: <BiMessageRoundedDots size={"1.3em"} />,
    //         // onClick: (e) =>
    //         //   handleMenuClick(

    //         //   ),
    //       },
    //     ];
    //     return (
    //       <Dropdown menu={{ items: menuItems }} className="text-[14px]">
    //         {/* <IoMdMore size={"2em"} className="text-[#6D31ED]" /> */}
    //       </Dropdown>
    //     );
    //   },
    //   width: "6%",
    // },
  ];

  const handlerClick = (e: any) => {};

  const tabNames = ["All", "Visitor", "Exhibitor", "Sponsor"];
  return (
    <div className="bg-white p-4 rounded-[20px] drop-shadow-[0_3px_6px_rgba(102,102,234,0.1)] mt-10">
      <section className="flex items-center justify-between">
        <div className="flex items-center  m-3  gap-4">
          <div>
            <IoIosPeople size={"3em"} className=" text-[#6D31ED] " />
          </div>
          <h1 className="text-[30px] font-[600] text-[#0F0F37]">Attendees</h1>
        </div>
        <section className="flex">
          <Link
            to={`/event/${id}/role_function`}
            className="flex gap-4 ml-auto mr-4 items-center"
          >
            <div className="text-white flex gap-3 items-center justify-center py-2 px-3 bg-default_blue rounded-full">
              <IoIosArrowDropright size={"1.6em"} />{" "}
              <span>Manage Attendee</span>
            </div>
          </Link>
          {/* <div className="flex gap-4 ml-auto mr-4 items-center">
            <Link
              className=" flex gap-3 items-center justify-center py-2 px-3
             bg-white border border-default_blue text-default_blue
             rounded-full"
              to="/events/create_events"
            >
              <IoIosArrowDropright size={"1.6em"} />{" "}
              <span>Bulk Add attendee</span>
            </Link>
          </div> */}
        </section>
      </section>

      <div className="flex items-center my-5 gap-2 ">
        {tabNames.map((d, i) => (
          <div
            key={i}
            className={`${
              activeTab === i
                ? "border-b-2 border-t-0 border-l-0 border-r-0 border-[#6666ea]  text-[#252363]"
                : "bg-white text-[#252363]"
            }  px-6 py-2  text-[16px] font-[300]   cursor-pointer`}
            onClick={() => setActiveTab(i)}
          >
            <span>{d}</span>
          </div>
        ))}
      </div>
      <div className="flex justify-between py-6   bg-white rounded-[10px]">
        <div className=" w-full  flex items-center gap-4">
          <div>
            <Input
              size="large"
              placeholder="Search"
              className="noplaceholder rounded-[20px] border-[1px] border-[#9494f5] text-[#9494f5]"
              suffix={<CiSearch />}
            />
            <br />
          </div>

          <div className="h-[40px] w-[142px]">
            <Select
              defaultValue="Verified"
              allowClear
              className="roundedSelect w-full h-full"
              options={[{ value: "Filter", label: "Filter" }]}
            />
          </div>
        </div>
      </div>
      <DataTable
        className="mt-4"
        columns={columns}
        progressPending={isLoading}
        data={AllAllAttendee}
        paginationServer
        progressComponent={<Spin />}
        pagination
        paginationTotalRows={total}
        pointerOnHover
        highlightOnHover
        customStyles={customStyles}
        onRowClicked={(selected: any) =>
          navigate(`/events/${id}/attendee/${selected?._id}`)
        }
      />
      {/* {EditOpen && (
          <BulkAddAttendee
            open={EditOpen}
            setOpenSMS={setEditOpen}
            roleID={roleID}
            handleReFetch={() => setRefresh(!refresh)}
          />
        )} */}
    </div>
  );
};

export default Attendee;
